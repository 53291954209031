import React from "react";
import styled from "styled-components";
import { Switch, Route } from "react-router-dom";

import { ROUTER } from "../../config";
import { isAuthenticated } from "../../helpers";

import Homepage from "../../pages/homepage";
import Rules from "../../pages/rules-page";
import Schedule from "../../pages/schedule-page";
import PrizePool from "../../pages/prize_pool-page";

import PageNotFound from "../../pages/page-not-found";
import NeedAuth from "../../pages/need-auth";
import RulesAsia from "../../pages/rules-asia";
import EmailConfirm from "../../pages/email-confirm";
const StyledMain = styled.main`
  flex: 1;
`;

const PrivateRoute = ({ children, ...rest }) => {
  if (!isAuthenticated()) {
    return <NeedAuth />;
  }

  return <Route {...rest} />;
};

const Main = () => {
  return (
    <StyledMain>
      <Switch>
        <Route path={ROUTER.homepage} exact component={Homepage} />
        <Route path={ROUTER.rules} exact component={Rules} />
        <Route path={ROUTER.schedule} exact component={Schedule} />
        <Route path={ROUTER.prize_pool} exact component={PrizePool} />
        <Route path={ROUTER.rulebook_asia} exact component={RulesAsia} />
        <Route path={ROUTER.emailConfirm} exact component={EmailConfirm} />

        <Route path="*" exact component={PageNotFound} />
      </Switch>
    </StyledMain>
  );
};

export default Main;
