import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import s from "./successful-message.module.scss";

const SuccessfulMessage = ({ message, languageDataLanding }) => {
  return (
    <div className={s.whiteColor}>
      <div className={s.title}>
        {/* <p>Thank you for joining the IeSF Big Bang Asia Open!</p> */}
      </div>
      {languageDataLanding(message) ? (
        <p> {languageDataLanding(message)}</p>
      ) : (
        <>
          <p>Thank you for joining the IeSF Big Bang Asia Open!</p>
          <p>
            To finish the registration, please login{" "}
            <a href="https://bigbang.passport.gg/">to the Tournament hub</a> and
            fill in the necessary data. Make sure to enter accurate data
            regarding you (name, age, country) – this information will be
            verified occasionally, participants that will provide false
            information, will be disqualified. In case of the team games, if one
            player will be disqualified, this will affect the whole team.
          </p>
          <p>
            For individual games (Real Cricket, eFootball) select the
            competition level that corresponds to your skills and register for
            the competition.
          </p>{" "}
          <p>
            For team games (Clash Of Clans, Dota 2 and PUBG Mobile) gather your
            team (invite friends via the internal form or just share this page)
            and register the full team to the competition.
          </p>
          <span>
            <p>
              Check{" "}
              <a href="/rulebook-asia" target="_blank">
                rulebook
              </a>
              ,{" "}
              <a target="_blank" href="/schedule_AOCH2022">
                schedule
              </a>{" "}
              and other information for detail.
            </p>
          </span>{" "}
          <p style={{ textAlign: "center" }}>GLFH</p>
        </>
      )}
      {/* <p>
        To finish the registration, please login{" "}
        <a href="https://bigbang.passport.gg/">to the Tournament hub</a> and
        fill in the necessary data. Make sure to enter accurate data regarding
        you (name, age, country) – this information will be verified
        occasionally, participants that will provide false information, will be
        disqualified. In case of the team games, if one player will be
        disqualified, this will affect the whole team.
      </p> */}
      {/* <p>
        For individual games (Real Cricket, eFootball) select the competition
        level that corresponds to your skills and register for the competition.
      </p> */}
      {/* <p>
        For team games (Clash Of Clans, Dota 2 and PUBG Mobile) gather your team
        (invite friends via the internal form or just share this page) and
        register the full team to the competition.
      </p> */}
      {/* 
      <span>
        <p>
          Check{" "}
          <a href="/rulebook-asia" target="_blank">
            rulebook
          </a>
          ,{" "}
          <a target="_blank" href="/schedule_AOCH2022">
            schedule
          </a>{" "}
          and other information for detail.
        </p>
      </span> */}
      {/* <p style={{ textAlign: "center" }}>GLFH</p> */}
    </div>
  );
};
export default SuccessfulMessage;
