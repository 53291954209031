import styled from "styled-components";
import picHeaderBG from "./img/header-bg.png";
import bgMainBefore from "../../img/main-b.png";

//color tchema
import {
  mainBGColor,
  borderColor,
  bgColorLite,
  BTNColor,
  BTNRegistrationColor,
  BTNRegistrationBorder,
  PrimaryTextColor,
  PrimaryBackgroundGradientColor,
} from "../../total/const";

export const Styled = {
  ButtonGradient: styled.button`
    border-radius: 4px;
    border: 0;
    color: #fff;
    font-weight: 600;
    padding: 2rem 10rem;
    transition: all 0.3s ease;
    position: relative;
    z-index: 3;
    height: 8rem;
    display: block;
    font-size: 2.8rem;
    margin: 4rem auto 16rem;
    line-height: 1;
    cursor: pointer;

    @media (max-width: 865px) {
      margin: 4rem auto 4rem;
      font-size: 2rem;
      padding: 1rem 4rem;
      height: 5rem;
    }

    &::before {
      content: "";
      left: -4px;
      top: -4px;
      position: absolute;
      background: radial-gradient(
        92.35% 1417.49% at 10.36% 7.65%,
        #c8a141 0%,
        #fbf7c8 34.28%,
        #c8a141 73.44%,
        #fbf7c8 100%
      );
      border-radius: 4px;
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      border: 0;
      z-index: -1;
    }

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      background: #661024;
      left: 0;
      z-index: -1;
      transition: all 0.3s;
    }

    &:hover {
      opacity: 0.8;
    }
  `,
  Header: styled.section`
    display: flex;
    flex-direction: column;
    position: relative;
    background: radial-gradient(
      36.74% 182.38% at 6.22% 67.44%,
      #661024 0%,
      #be2a46 34.36%,
      #661024 100%
    );

    .linkContainer * {
      display: inline;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 50%;
      min-height: 120%;
      top: 0;
      background: url(${bgMainBefore}) left center no-repeat;
      left: -10rem;
      bottom: 0;
      background-size: 100%;
      z-index: 0;
    }

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2rem 0;
    }
    .topImages {
      display: flex;
      align-items: center;
      @media (max-width: 767px) {
        flex-wrap: wrap;
        flex-direction: column;
      }
    }

    .title {
      .logo {
        img {
          display: block;
          max-height: 158px;
          @media (max-width: 767px) {
            max-height: 90px;
          }
        }

        &-sm {
          display: none;

          @media (max-width: 767px) {
            display: block;
            width: 12rem;
          }
        }

        &-lg {
          display: block;
          img {
            width: 100%;
          }
          @media (max-width: 767px) {
            display: none;
          }
        }
      }
    }

    .bottom {
      @media (max-width: 1199px) {
      }

      @media (max-width: 767px) {
        br {
          display: none;
        }
      }

      h1 {
        margin: 0 0 30px;
        font-weight: bold;
        font-size: 48px;
        text-align: center;
        justify-content: center;
        display: flex;

        @media (max-width: 867px) {
          font-size: 24px;
          margin: 0 0 10px;
        }
      }

      > div {
        font-weight: 500;
        font-size: 21px;
        line-height: 1.5;
        margin: 0 auto 20px;
        text-align: center;

        max-width: 110rem;
        position: relative;
        z-index: 3;

        a,
        p,
        div,
        span {
          color: white;
        }

        * {
          display: inline;
          text-align: center;
        }

        @media (max-width: 767px) {
          font-size: 16px;
          margin: 0 0 30px;
        }
      }
    }
  `,
  Button: styled.button`
    border-radius: 3px;
    border: ${BTNRegistrationBorder};
    background: ${BTNRegistrationColor};
    height: 50px;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    padding: 0 50px;
    //cursor: pointer;

    transition: all 0.3s ease;

    @media (max-width: 767px) {
      padding: 0 15px;
      height: 40px;
    }

    &:hover,
    &:focus {
      background: #d5910f;
      border-color: #d5910f;
    }

    &.login {
      border: 0;
      color: ${PrimaryTextColor};
      background: ${BTNColor};
      transition: all 0.3s;
      font-size: 2rem;
      cursor: pointer;
      min-width: 187px;

      @media (max-width: 765px) {
        font-size: 1.6rem;
        min-width: 127px;
      }

      &:hover,
      &:focus {
        color: ${BTNColor};
        background: ${PrimaryBackgroundGradientColor};
        border-color: ${BTNColor};
      }
    }
  `,
  FlexGroup: styled.div`
    display: flex;
    align-items: center;

    button {
      margin-left: 24px;
    }
  `,
};
