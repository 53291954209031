import styled from "styled-components";
//color tchema
import {
  mainBGColor,
  borderColor,
  bgColorLite,
  BTNColor,
} from "../../total/const";

export const Styled = {
  Wrapper: styled.div`
    //  display: none;
    position: relative;

    .current {
      display: flex;
      align-items: center;
      cursor: pointer;

      &__lang {
        color: ${BTNColor};
        font-weight: bold;
        margin-right: 8px;
        font-size: 2.4rem;
        text-transform: uppercase;

        @media (max-width: 765px) {
          font-size: 1.6rem;
        }
      }
    }

    .dropdown {
      position: absolute;
      right: 0;
      top: 100%;
    }
  `,
  Dropdown: styled.div`
    position: absolute;
    z-index: 5;
    top: 100%;
    right: 0;
    margin-top: 10px;

    background: ${BTNColor};
    box-shadow: 0 0 10px ${BTNColor};
    border-radius: 3px;
    padding: 5px 10px;

    &:before {
      content: "";
      display: block;
      border: 6px solid transparent;
      border-bottom-color: ${BTNColor};
      position: absolute;
      bottom: 100%;
      right: 3px;
    }

    > div {
      color: #140f2b;
      font-weight: bold;
      cursor: pointer;
      padding: 5px;
      text-transform: uppercase;
      transition: color 0.3s ease;
    }
  `,
};
