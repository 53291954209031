import styled from "styled-components";
import logoLG from "../../img/big-logo.png";
import React, { Component } from "react";

export default function Logo() {
  return (
    <StyledLogo>
      <img style={{ width: "300px" }} src={logoLG} alt="logo" />
    </StyledLogo>
  );
}

const StyledLogo = styled.div`
  width: max-content;
  margin: 0 auto;
  max-width: 320px;

  @media (max-width: 350px) {
    width: 60%;

    & > img {
      width: 100%;
    }
  }
`;
