import React from "react";
import LangSwitcher from "../../components/lang-switcher";
import { FormattedMessage } from "react-intl";

import logoLG from "../../img/big-logo.png";

//color tchema
import { mainBGColor } from "../../total/const";

import s from "./smollHeader.module.scss";

const SmollHeader = ({ title }) => {
  return (
    <>
      <div className={s.mainContainer} style={{ background: mainBGColor }}>
        <div className={s.contentContainer}>
          <div className={s.flexContainer}>
            <div className={s.imgContainer} onClick={() => window.location.href = '/'}>
              <img src={logoLG} alt="logo" />
            </div>
            <div className={s.title}>{title}</div>
            <div className={s.lang}>
              <LangSwitcher />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SmollHeader;
