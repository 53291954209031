import styled from "styled-components";

export const Styled = {
  Button: styled.button`
    height: 40px;
    border-radius: 3px;
    border: none;
    font-weight: bold;
    font-size: 16px;
    transition: all 0.3s ease;
    padding: 0 20px;
    cursor: pointer;
    outline: none;

    @media (max-width: 767px) {
      height: 36px;
      font-size: 14px;
    }

    &.primary {
      color: #fff;
      background: #eda211;

      &:hover,
      &:focus {
        background: #bd810d;
      }

      &:active {
        background: #a5710b;
      }
    }
    &.bigBang {
      color: #fff;
      background: radial-gradient(
        104.65% 533.13% at 0.94% 53.84%,
        #ca2d4a 0,
        #661024 44.13%,
        #ca2d4a 76.01%,
        #661024 100%
      );
      &:hover,
      &:focus {
        background: radial-gradient(
          104.65% 533.13% at 0.94% 53.84%,
          #ca2d4a 0,
          #661024b3 44.13%,
          #ca2d4aab 76.01%,
          #661024b3 100%
        );
      }

      &:active {
        background: #a5710b;
      }
    }

    &.secondary {
      color: #201941;
      background: #d5cbff;

      &:hover,
      &:focus {
        background: #b09dfe;
      }

      &:active {
        background: #8b6ffe;
      }
    }

    &.sm {
      height: 36px;
      padding: 0 12px;
      font-size: 14px;
    }

    &.full-width {
      width: 100%;
    }

    &[disabled] {
      background: #999;
      pointer-events: none;
    }
  `,
};
