import React from "react";
import { ROUTER } from "../../config";
import { Styled } from "./style";
import { SOCIAL_LINKS } from "../../config";
import { FormattedMessage } from "react-intl";
import log from '../../img/app-passport.png';
import {
  getCookiesPolicyLink,
  getPrivacyPolicyLink,
  getTermsLink,
  getRulebookLink,
} from "../../helpers";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  IconFacebook,
  IconInstagramm,
  IconTelegram,
  IconTwitter,
  IconVK,
} from "../social-icons";

const Footer = () => {
  const interfaceLang = useSelector<RootState>((store) => store.interfaceLang);

  if (window.location.pathname === ROUTER.messenger) return null;

  return (
    <Styled.Footer>
      <Styled.Container>
        <Styled.Row>
          <Styled.Col>
            <Styled.NavItem>
              <a
                href={getTermsLink("rule")}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="footer.termsOfUse" />
              </a>
            </Styled.NavItem>
            <Styled.NavItem>
              <a
                href={getPrivacyPolicyLink(interfaceLang)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="footer.privacyPolicy" />
              </a>
            </Styled.NavItem>
            <Styled.NavItem>
              <a
                href={getCookiesPolicyLink(interfaceLang)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="footer.cookiesPolicy" />
              </a>
            </Styled.NavItem>
            <Styled.NavItem>
              <a
                href={getRulebookLink(interfaceLang)}
                rel="noopener noreferrer"
              >
                <FormattedMessage id="footer.rulebookPolicy" />
              </a>
            </Styled.NavItem>
          </Styled.Col>
          <Styled.Col>
            <Styled.NavItem>
              <div className="row-log">
                <p className="text">
                  Powered by{" "}
                  <a className="main-link" href="https://about.passport.gg/">
                    Passport.GG
                  </a>{" "}
                </p>
                <img src={log} alt="app-logo"/>
              </div>
            </Styled.NavItem>
          </Styled.Col>
          <Styled.Col>
            <div className="right-content">
              <a href="https://about.passport.gg/gamersupport " target="_blank">
                <div className="support">
                  {" "}
                  <FormattedMessage id="footer.support" />
                </div>
              </a>
              <div>
                <Styled.SocialTitle>
                  <FormattedMessage id="footer.followUs" />
                </Styled.SocialTitle>
                <Styled.SocialLinks>
                  <Styled.SocialLink
                    target="_blank"
                    href="https://www.facebook.com/BigBangSports.gg"
                  >
                    <IconFacebook />
                  </Styled.SocialLink>
                  <Styled.SocialLink
                    target="_blank"
                    href="https://www.instagram.com/bigbangsports.gg/"
                  >
                    <IconInstagramm />
                  </Styled.SocialLink>
                  <Styled.SocialLink
                    target="_blank"
                    href="https://twitter.com/BigBangSports_"
                  >
                    <IconTwitter />
                  </Styled.SocialLink>
                </Styled.SocialLinks>
              </div>
            </div>
          </Styled.Col>
        </Styled.Row>
      </Styled.Container>
    </Styled.Footer>
  );
};

export default Footer;
