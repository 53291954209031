import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

import { Container } from "../UI";
import store from "../../redux/store";
import { showAuthModal, showRegisterModal } from "../../redux/actions/auth";
import { RootState } from "../../redux/store";
import { ROUTER } from "../../config";
import { Styled } from "./style";
import LangSwitcher from "../lang-switcher";
import LangSwitcherLanding from "../lang-switcher/landing";

import country from "./img/noFlag.jpg";
import logoLG from "../../img/big-logo.png";
import styles from "./styles.module.scss";

export default function PublicHeader({ landingData, languageDataLanding }) {
  const { pathname } = useLocation();
  const toHomepage = (e) => {
    e.preventDefault();
    //history.push(isUserOnline ? '/ratings' : ROUTER.homepage)
  };
  /* const goToUrl = (url: 'String') => {
      history.push(url);
   } */
  console.log(languageDataLanding(landingData?.textBlockAbout3));

  return (
    <Styled.Header>
      <Container>
        <div className="top">
          <div className="topImages">
            <a
              target="_blank"
              href="https://asia.passport.gg"
              className="title" /* onClick={e => toHomepage(e)} */
            >
              <div className="logo logo-sm">
                <img src={logoLG} alt="passport.gg" />
              </div>
              <div className="logo logo-lg">
                <img src={logoLG} alt="passport.gg" />
              </div>
            </a>
            <a
              target="_blank"
              href="https://asia.passport.gg"
              className="title" /* onClick={e => toHomepage(e)} */
            >
              <div className="logo logo-sm">
                <img
                  src={
                    landingData?.countryFlag
                      ? landingData?.countryFlag
                      : country
                  }
                  alt="passport.gg"
                />
              </div>
              <div className="logo logo-lg">
                <img
                  src={
                    landingData?.countryFlag
                      ? landingData?.countryFlag
                      : country
                  }
                  alt="passport.gg"
                />
              </div>
            </a>
          </div>

          <Styled.FlexGroup>
            <LangSwitcherLanding
              mainLandingLang={landingData?.mainLandingLang}
              languages={landingData?.languages}
            />
            <Styled.Button
              className="login"
              onClick={() =>
                (window.location.href = "https://bigbang.passport.gg/")
              }
            >
              <FormattedMessage id="global.buttons.login" />
            </Styled.Button>
          </Styled.FlexGroup>
        </div>
        <div className={classNames("bottom", styles.bottom)}>
          <div className="linkContainer" style={{ color: "white" }}>
            {languageDataLanding(landingData?.textBlockContent1) ? (
              <h1> {languageDataLanding(landingData?.textBlockContent1)}</h1>
            ) : (
              <>
                <FormattedMessage id="publicHeader.h1.first" tagName="h1" />
                <a href={`/prize_pool_AOCH2022`} style={{ color: "white" }}>
                  <FormattedMessage id="publicHeader.h1.link" tagName="h1" />
                </a>
                <FormattedMessage id="publicHeader.h1.last" tagName="h1" />
              </>
            )}
          </div>
          <div className="linkContainer" style={{ color: "white" }}>
            {languageDataLanding(landingData?.textBlockContent2) ? (
              <div> {languageDataLanding(landingData?.textBlockContent2)}</div>
            ) : (
              <>
                {" "}
                <FormattedMessage id="publicHeader.subtitle1" tagName="div" />
                <a
                  href={`/rules_AOCH2022/#eligibility`}
                  style={{ color: "white" }}
                >
                  <FormattedMessage
                    id="publicHeader.subtitle.link1"
                    tagName="div"
                  />
                </a>
                <FormattedMessage id="publicHeader.subtitle2" tagName="div" />
                <a href={`/rules_AOCH2022#country`} style={{ color: "white" }}>
                  <FormattedMessage
                    id="publicHeader.subtitle.link2"
                    tagName="div"
                  />
                </a>
                <FormattedMessage id="publicHeader.subtitle3" tagName="div" />
              </>
            )}
          </div>
          <Styled.ButtonGradient
            onClick={() => {
              // store.dispatch(showRegisterModal());
              // store.dispatch(showAuthModal());
            }}
          >
            {languageDataLanding(landingData?.textBlockButtons1) ? (
              languageDataLanding(landingData?.textBlockButtons1)
            ) : (
              <FormattedMessage id="global.buttons.signUp" />
            )}
          </Styled.ButtonGradient>
        </div>
      </Container>
    </Styled.Header>
  );
}
