import React from 'react';
import { FormattedMessage } from "react-intl";

//color tchema
import { mainBGColor } from '../../total/const';

import s from './prizePool.module.scss';
import SmollHeader from '../smoll-header';
import countryFlag from '../../img/triple01.jpg';

const PrizePool = (props) => {
   return (
      <>
         <SmollHeader />
         <div className={s.blockContainer}>
            <div className={`${s.contentContainer} ${s.textBalck}`}>
               <FormattedMessage id="pages.prize_pool.mainTitle" tagName="h1" />
               <div className={s.text}>
                  <p>
                     The total prize pool of IeSF Big Bang Asia Open Championship is 100 000 (one hundred thousand) US dollars. This is a total sum and depending on the taxation regime of the winners taxes may be applied. The prize pool by game is provided without applicable taxes and costs that may be applied and final sum received by the winners may vary. For the team games, the prizepool will be paid to the captain of the team, unless another is confirmed separately. Every winner will have to provide:
                  </p>
                  <p>For Indian Residents - copy of pan card, cancelled cheque/bank account details</p>
                  <p>For Other Countries Residents - Identity proof for name and address, Tax Identification Number if any, Bank details including Swift/IBAN/Sort Code with winner’s name of account.</p>
                  <p>The prize pool for each game and level is presented below.</p>
               </div>
               <h4>Dota 2</h4>

               <div className={s.tableContainer}>
                  <div className={s.tBody}>
                     <div className={`${s.tRow} ${s.smal}`} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <FormattedMessage id="pages.prize_pool.table.header1" tagName="p" />
                        </div>
                        <div className={s.item}>
                           <FormattedMessage id="pages.prize_pool.table.header2" tagName="p" />
                        </div>
                        <div className={s.item}>
                           <FormattedMessage id="pages.prize_pool.table.header3" tagName="p" />
                        </div>
                        <div className={s.item}>
                           <FormattedMessage id="pages.prize_pool.table.header4" tagName="p" />
                        </div>
                     </div>
                     <div className={s.tRow}>
                        <div className={s.item}>
                           <p>1</p>
                        </div>
                        <div className={s.item}>
                           <p>$6 000</p>
                        </div>
                        <div className={s.item}>
                           <p>$3 600</p>
                        </div>
                        <div className={s.item}>
                           <p>$2 400</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                          <p>2</p>
                        </div>
                        <div className={s.item}>
                           <p>$3 375</p>
                        </div>
                        <div className={s.item}>
                           <p>$2 025</p>
                        </div>
                        <div className={s.item}>
                           <p>$1 350</p>
                        </div>
                     </div>
                     <div className={s.tRow}>
                        <div className={s.item}>
                           <p>3</p>
                        </div>
                        <div className={s.item}>
                           <p>$1 875</p>
                        </div>
                        <div className={s.item}>
                           <p>$1 125</p>
                        </div>
                        <div className={s.item}>
                           <p>$750</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>4</p>
                        </div>
                        <div className={s.item}>
                           <p>$750</p>
                        </div>
                        <div className={s.item}>
                           <p>$450</p>
                        </div>
                        <div className={s.item}>
                           <p>$300</p>
                        </div>
                     </div>
                     <div className={s.tRow} >
                        <div className={s.item}>
                           <p>5</p>
                        </div>
                        <div className={s.item}>
                           <p>$375</p>
                        </div>
                        <div className={s.item}>
                           <p>$225</p>
                        </div>
                        <div className={s.item}>
                           <p>$150</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>6</p>
                        </div>
                        <div className={s.item}>
                           <p>$375</p>
                        </div>
                        <div className={s.item}>
                           <p>$225</p>
                        </div>
                        <div className={s.item}>
                           <p>$150</p>
                        </div>
                     </div>
                     <div className={s.tRow}>
                        <div className={s.item}>
                           <p>7</p>
                        </div>
                        <div className={s.item}>
                           <p>$375</p>
                        </div>
                        <div className={s.item}>
                           <p>$225</p>
                        </div>
                        <div className={s.item}>
                           <p>$150</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>8</p>
                        </div>
                        <div className={s.item}>
                           <p>$375</p>
                        </div>
                        <div className={s.item}>
                           <p>$225</p>
                        </div>
                        <div className={s.item}>
                           <p>$150</p>
                        </div>
                     </div>
                     <div className={s.tRow}>
                        <div className={s.item}>
                           <p>9</p>
                        </div>
                        <div className={s.item}>
                           <p>$188</p>
                        </div>
                        <div className={s.item}>
                           <p>$113</p>
                        </div>
                        <div className={s.item}>
                           <p>$75</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>10</p>
                        </div>
                        <div className={s.item}>
                           <p>$188</p>
                        </div>
                        <div className={s.item}>
                           <p>$113</p>
                        </div>
                        <div className={s.item}>
                           <p>$75</p>
                        </div>
                     </div>
                     <div className={s.tRow}>
                        <div className={s.item}>
                           <p>11</p>
                        </div>
                        <div className={s.item}>
                           <p>$188</p>
                        </div>
                        <div className={s.item}>
                           <p>$113</p>
                        </div>
                        <div className={s.item}>
                           <p>$75</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>12</p>
                        </div>
                        <div className={s.item}>
                           <p>$188</p>
                        </div>
                        <div className={s.item}>
                           <p>$113</p>
                        </div>
                        <div className={s.item}>
                           <p>$75</p>
                        </div>
                     </div>
                     <div className={s.tRow}>
                        <div className={s.item}>
                           <p>13</p>
                        </div>
                        <div className={s.item}>
                           <p>$188</p>
                        </div>
                        <div className={s.item}>
                           <p>$113</p>
                        </div>
                        <div className={s.item}>
                           <p>$75</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>14</p>
                        </div>
                        <div className={s.item}>
                           <p>$188</p>
                        </div>
                        <div className={s.item}>
                           <p>$113</p>
                        </div>
                        <div className={s.item}>
                           <p>$75</p>
                        </div>
                     </div>
                     <div className={s.tRow}>
                        <div className={s.item}>
                           <p>15</p>
                        </div>
                        <div className={s.item}>
                           <p>$188</p>
                        </div>
                        <div className={s.item}>
                           <p>$113</p>
                        </div>
                        <div className={s.item}>
                           <p>$75</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>16</p>
                        </div>
                        <div className={s.item}>
                           <p>$188</p>
                        </div>
                        <div className={s.item}>
                           <p>$113</p>
                        </div>
                        <div className={s.item}>
                           <p>$75</p>
                        </div>
                     </div>
                  </div>
               </div>
               <h4>PUBG Mobile</h4>

               <div className={s.tableContainer}>
                  <div className={s.tBody}>
                     <div className={`${s.tRow} ${s.smal}`} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <FormattedMessage id="pages.prize_pool.table.header1" tagName="p" />
                        </div>
                        <div className={s.item}>
                           <FormattedMessage id="pages.prize_pool.table.header2" tagName="p" />
                        </div>
                        <div className={s.item}>
                           <FormattedMessage id="pages.prize_pool.table.header3" tagName="p" />
                        </div>
                        <div className={s.item}>
                           <FormattedMessage id="pages.prize_pool.table.header4" tagName="p" />
                        </div>
                     </div>
                     <div className={s.tRow}>
                        <div className={s.item}>
                           <p>1</p>
                        </div>
                        <div className={s.item}>
                           <p>$6 000</p>
                        </div>
                        <div className={s.item}>
                           <p>$3 600</p>
                        </div>
                        <div className={s.item}>
                           <p>$2 400</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>2</p>
                        </div>
                        <div className={s.item}>
                           <p>$3 375</p>
                        </div>
                        <div className={s.item}>
                           <p>$2 025</p>
                        </div>
                        <div className={s.item}>
                           <p>$1 350</p>
                        </div>
                     </div>
                     <div className={s.tRow}>
                        <div className={s.item}>
                           <p>3</p>
                        </div>
                        <div className={s.item}>
                           <p>$1 875</p>
                        </div>
                        <div className={s.item}>
                           <p>$1 125</p>
                        </div>
                        <div className={s.item}>
                           <p>$750</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>4</p>
                        </div>
                        <div className={s.item}>
                           <p>$750</p>
                        </div>
                        <div className={s.item}>
                           <p>$450</p>
                        </div>
                        <div className={s.item}>
                           <p>$300</p>
                        </div>
                     </div>
                     <div className={s.tRow} >
                        <div className={s.item}>
                           <p>5</p>
                        </div>
                        <div className={s.item}>
                           <p>$375</p>
                        </div>
                        <div className={s.item}>
                           <p>$225</p>
                        </div>
                        <div className={s.item}>
                           <p>$150</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>6</p>
                        </div>
                        <div className={s.item}>
                           <p>$375</p>
                        </div>
                        <div className={s.item}>
                           <p>$225</p>
                        </div>
                        <div className={s.item}>
                           <p>$150</p>
                        </div>
                     </div>
                     <div className={s.tRow}>
                        <div className={s.item}>
                           <p>7</p>
                        </div>
                        <div className={s.item}>
                           <p>$375</p>
                        </div>
                        <div className={s.item}>
                           <p>$225</p>
                        </div>
                        <div className={s.item}>
                           <p>$150</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>8</p>
                        </div>
                        <div className={s.item}>
                           <p>$375</p>
                        </div>
                        <div className={s.item}>
                           <p>$225</p>
                        </div>
                        <div className={s.item}>
                           <p>$150</p>
                        </div>
                     </div>
                     <div className={s.tRow}>
                        <div className={s.item}>
                           <p>9</p>
                        </div>
                        <div className={s.item}>
                           <p>$188</p>
                        </div>
                        <div className={s.item}>
                           <p>$113</p>
                        </div>
                        <div className={s.item}>
                           <p>$75</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>10</p>
                        </div>
                        <div className={s.item}>
                           <p>$188</p>
                        </div>
                        <div className={s.item}>
                           <p>$113</p>
                        </div>
                        <div className={s.item}>
                           <p>$75</p>
                        </div>
                     </div>
                     <div className={s.tRow}>
                        <div className={s.item}>
                           <p>11</p>
                        </div>
                        <div className={s.item}>
                           <p>$188</p>
                        </div>
                        <div className={s.item}>
                           <p>$113</p>
                        </div>
                        <div className={s.item}>
                           <p>$75</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>12</p>
                        </div>
                        <div className={s.item}>
                           <p>$188</p>
                        </div>
                        <div className={s.item}>
                           <p>$113</p>
                        </div>
                        <div className={s.item}>
                           <p>$75</p>
                        </div>
                     </div>
                     <div className={s.tRow}>
                        <div className={s.item}>
                           <p>13</p>
                        </div>
                        <div className={s.item}>
                           <p>$188</p>
                        </div>
                        <div className={s.item}>
                           <p>$113</p>
                        </div>
                        <div className={s.item}>
                           <p>$75</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>14</p>
                        </div>
                        <div className={s.item}>
                           <p>$188</p>
                        </div>
                        <div className={s.item}>
                           <p>$113</p>
                        </div>
                        <div className={s.item}>
                           <p>$75</p>
                        </div>
                     </div>
                     <div className={s.tRow}>
                        <div className={s.item}>
                           <p>15</p>
                        </div>
                        <div className={s.item}>
                           <p>$188</p>
                        </div>
                        <div className={s.item}>
                           <p>$113</p>
                        </div>
                        <div className={s.item}>
                           <p>$75</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>16</p>
                        </div>
                        <div className={s.item}>
                           <p>$188</p>
                        </div>
                        <div className={s.item}>
                           <p>$113</p>
                        </div>
                        <div className={s.item}>
                           <p>$75</p>
                        </div>
                     </div>
                  </div>
               </div>




               <h4>Clash of Clans</h4>

               <div className={s.tableContainer}>
                  <div className={s.tBody}>
                     <div className={`${s.tRow} ${s.smal}`} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <FormattedMessage id="pages.prize_pool.table.header1" tagName="p" />
                        </div>
                        <div className={s.item}>
                           <FormattedMessage id="pages.prize_pool.table.header2" tagName="p" />
                        </div>
                        <div className={s.item}>
                           <FormattedMessage id="pages.prize_pool.table.header3" tagName="p" />
                        </div>
                        <div className={s.item}>
                           <FormattedMessage id="pages.prize_pool.table.header4" tagName="p" />
                        </div>
                     </div>
                     <div className={s.tRow}>
                        <div className={s.item}>
                           <p>1</p>
                        </div>
                        <div className={s.item}>
                           <p>$3 000</p>
                        </div>
                        <div className={s.item}>
                           <p>$1 800</p>
                        </div>
                        <div className={s.item}>
                           <p>$1 200</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>2</p>
                        </div>
                        <div className={s.item}>
                           <p>$1 688</p>
                        </div>
                        <div className={s.item}>
                           <p>$1 013</p>
                        </div>
                        <div className={s.item}>
                           <p>$675</p>
                        </div>
                     </div>
                     <div className={s.tRow}>
                        <div className={s.item}>
                           <p>3</p>
                        </div>
                        <div className={s.item}>
                           <p>$938</p>
                        </div>
                        <div className={s.item}>
                           <p>$563</p>
                        </div>
                        <div className={s.item}>
                           <p>$375</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>4</p>
                        </div>
                        <div className={s.item}>
                           <p>$375</p>
                        </div>
                        <div className={s.item}>
                           <p>$225</p>
                        </div>
                        <div className={s.item}>
                           <p>$150</p>
                        </div>
                     </div>
                     <div className={s.tRow} >
                        <div className={s.item}>
                           <p>5</p>
                        </div>
                        <div className={s.item}>
                           <p>$188</p>
                        </div>
                        <div className={s.item}>
                           <p>$113</p>
                        </div>
                        <div className={s.item}>
                           <p>$75</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>6</p>
                        </div>
                        <div className={s.item}>
                           <p>$188</p>
                        </div>
                        <div className={s.item}>
                           <p>$113</p>
                        </div>
                        <div className={s.item}>
                           <p>$75</p>
                        </div>
                     </div>
                     <div className={s.tRow}>
                        <div className={s.item}>
                           <p>7</p>
                        </div>
                        <div className={s.item}>
                           <p>$188</p>
                        </div>
                        <div className={s.item}>
                           <p>$113</p>
                        </div>
                        <div className={s.item}>
                           <p>$75</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>8</p>
                        </div>
                        <div className={s.item}>
                           <p>$188</p>
                        </div>
                        <div className={s.item}>
                           <p>$113</p>
                        </div>
                        <div className={s.item}>
                           <p>$75</p>
                        </div>
                     </div>
                     <div className={s.tRow}>
                        <div className={s.item}>
                           <p>9</p>
                        </div>
                        <div className={s.item}>
                           <p>$94</p>
                        </div>
                        <div className={s.item}>
                           <p>$56</p>
                        </div>
                        <div className={s.item}>
                           <p>$38</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>10</p>
                        </div>
                        <div className={s.item}>
                           <p>$94</p>
                        </div>
                        <div className={s.item}>
                           <p>$56</p>
                        </div>
                        <div className={s.item}>
                           <p>$38</p>
                        </div>
                     </div>
                     <div className={s.tRow}>
                        <div className={s.item}>
                           <p>11</p>
                        </div>
                        <div className={s.item}>
                           <p>$94</p>
                        </div>
                        <div className={s.item}>
                           <p>$56</p>
                        </div>
                        <div className={s.item}>
                           <p>$38</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>12</p>
                        </div>
                        <div className={s.item}>
                           <p>$94</p>
                        </div>
                        <div className={s.item}>
                           <p>$56</p>
                        </div>
                        <div className={s.item}>
                           <p>$38</p>
                        </div>
                     </div>
                     <div className={s.tRow}>
                        <div className={s.item}>
                           <p>13</p>
                        </div>
                        <div className={s.item}>
                           <p>$94</p>
                        </div>
                        <div className={s.item}>
                           <p>$56</p>
                        </div>
                        <div className={s.item}>
                           <p>$38</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>14</p>
                        </div>
                        <div className={s.item}>
                           <p>$94</p>
                        </div>
                        <div className={s.item}>
                           <p>$56</p>
                        </div>
                        <div className={s.item}>
                           <p>$38</p>
                        </div>
                     </div>
                     <div className={s.tRow}>
                        <div className={s.item}>
                           <p>15</p>
                        </div>
                        <div className={s.item}>
                           <p>$94</p>
                        </div>
                        <div className={s.item}>
                           <p>$56</p>
                        </div>
                        <div className={s.item}>
                           <p>$38</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>16</p>
                        </div>
                        <div className={s.item}>
                           <p>$94</p>
                        </div>
                        <div className={s.item}>
                           <p>$56</p>
                        </div>
                        <div className={s.item}>
                           <p>$38</p>
                        </div>
                     </div>
                  </div>
               </div>

               <h4>eFootball</h4>

               <div className={s.tableContainer}>
                  <div className={s.tBody}>
                     <div className={`${s.tRow} ${s.smal}`} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <FormattedMessage id="pages.prize_pool.table.header1" tagName="p" />
                        </div>
                        <div className={s.item}>
                           <FormattedMessage id="pages.prize_pool.table.header2" tagName="p" />
                        </div>
                        <div className={s.item}>
                           <FormattedMessage id="pages.prize_pool.table.header3" tagName="p" />
                        </div>
                        <div className={s.item}>
                           <FormattedMessage id="pages.prize_pool.table.header4" tagName="p" />
                        </div>
                     </div>
                     <div className={s.tRow}>
                        <div className={s.item}>
                           <p>1</p>
                        </div>
                        <div className={s.item}>
                           <p>$3 000</p>
                        </div>
                        <div className={s.item}>
                           <p>$1 800</p>
                        </div>
                        <div className={s.item}>
                           <p>$1 200</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>2</p>
                        </div>
                        <div className={s.item}>
                           <p>$1 688</p>
                        </div>
                        <div className={s.item}>
                           <p>$1 013</p>
                        </div>
                        <div className={s.item}>
                           <p>$675</p>
                        </div>
                     </div>
                     <div className={s.tRow}>
                        <div className={s.item}>
                           <p>3</p>
                        </div>
                        <div className={s.item}>
                           <p>$938</p>
                        </div>
                        <div className={s.item}>
                           <p>$563</p>
                        </div>
                        <div className={s.item}>
                           <p>$375</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>4</p>
                        </div>
                        <div className={s.item}>
                           <p>$375</p>
                        </div>
                        <div className={s.item}>
                           <p>$225</p>
                        </div>
                        <div className={s.item}>
                           <p>$150</p>
                        </div>
                     </div>
                     <div className={s.tRow} >
                        <div className={s.item}>
                           <p>5</p>
                        </div>
                        <div className={s.item}>
                           <p>$188</p>
                        </div>
                        <div className={s.item}>
                           <p>$113</p>
                        </div>
                        <div className={s.item}>
                           <p>$75</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>6</p>
                        </div>
                        <div className={s.item}>
                           <p>$188</p>
                        </div>
                        <div className={s.item}>
                           <p>$113</p>
                        </div>
                        <div className={s.item}>
                           <p>$75</p>
                        </div>
                     </div>
                     <div className={s.tRow}>
                        <div className={s.item}>
                           <p>7</p>
                        </div>
                        <div className={s.item}>
                           <p>$188</p>
                        </div>
                        <div className={s.item}>
                           <p>$113</p>
                        </div>
                        <div className={s.item}>
                           <p>$75</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>8</p>
                        </div>
                        <div className={s.item}>
                           <p>$188</p>
                        </div>
                        <div className={s.item}>
                           <p>$113</p>
                        </div>
                        <div className={s.item}>
                           <p>$75</p>
                        </div>
                     </div>
                     <div className={s.tRow}>
                        <div className={s.item}>
                           <p>9</p>
                        </div>
                        <div className={s.item}>
                           <p>$94</p>
                        </div>
                        <div className={s.item}>
                           <p>$56</p>
                        </div>
                        <div className={s.item}>
                           <p>$38</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>10</p>
                        </div>
                        <div className={s.item}>
                           <p>$94</p>
                        </div>
                        <div className={s.item}>
                           <p>$56</p>
                        </div>
                        <div className={s.item}>
                           <p>$38</p>
                        </div>
                     </div>
                     <div className={s.tRow}>
                        <div className={s.item}>
                           <p>11</p>
                        </div>
                        <div className={s.item}>
                           <p>$94</p>
                        </div>
                        <div className={s.item}>
                           <p>$56</p>
                        </div>
                        <div className={s.item}>
                           <p>$38</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>12</p>
                        </div>
                        <div className={s.item}>
                           <p>$94</p>
                        </div>
                        <div className={s.item}>
                           <p>$56</p>
                        </div>
                        <div className={s.item}>
                           <p>$38</p>
                        </div>
                     </div>
                     <div className={s.tRow}>
                        <div className={s.item}>
                           <p>13</p>
                        </div>
                        <div className={s.item}>
                           <p>$94</p>
                        </div>
                        <div className={s.item}>
                           <p>$56</p>
                        </div>
                        <div className={s.item}>
                           <p>$38</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>14</p>
                        </div>
                        <div className={s.item}>
                           <p>$94</p>
                        </div>
                        <div className={s.item}>
                           <p>$56</p>
                        </div>
                        <div className={s.item}>
                           <p>$38</p>
                        </div>
                     </div>
                     <div className={s.tRow}>
                        <div className={s.item}>
                           <p>15</p>
                        </div>
                        <div className={s.item}>
                           <p>$94</p>
                        </div>
                        <div className={s.item}>
                           <p>$56</p>
                        </div>
                        <div className={s.item}>
                           <p>$38</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>16</p>
                        </div>
                        <div className={s.item}>
                           <p>$94</p>
                        </div>
                        <div className={s.item}>
                           <p>$56</p>
                        </div>
                        <div className={s.item}>
                           <p>$38</p>
                        </div>
                     </div>
                  </div>
               </div>



               <h4>Real Cricket</h4>

               <div className={s.tableContainer}>
                  <div className={s.tBody}>
                     <div className={`${s.tRow} ${s.smal}`} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <FormattedMessage id="pages.prize_pool.table.header1" tagName="p" />
                        </div>
                        <div className={s.item}>
                           <FormattedMessage id="pages.prize_pool.table.header2" tagName="p" />
                        </div>
                        <div className={s.item}>
                           <FormattedMessage id="pages.prize_pool.table.header3" tagName="p" />
                        </div>
                        <div className={s.item}>
                           <FormattedMessage id="pages.prize_pool.table.header4" tagName="p" />
                        </div>
                     </div>
                     <div className={s.tRow}>
                        <div className={s.item}>
                           <p>1</p>
                        </div>
                        <div className={s.item}>
                           <p>$2 000</p>
                        </div>
                        <div className={s.item}>
                           <p>$1 200</p>
                        </div>
                        <div className={s.item}>
                           <p>$800</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>2</p>
                        </div>
                        <div className={s.item}>
                           <p>$1 125</p>
                        </div>
                        <div className={s.item}>
                           <p>$675</p>
                        </div>
                        <div className={s.item}>
                           <p>$450</p>
                        </div>
                     </div>
                     <div className={s.tRow}>
                        <div className={s.item}>
                           <p>3</p>
                        </div>
                        <div className={s.item}>
                           <p>$625</p>
                        </div>
                        <div className={s.item}>
                           <p>$375</p>
                        </div>
                        <div className={s.item}>
                           <p>$250</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>4</p>
                        </div>
                        <div className={s.item}>
                           <p>$250</p>
                        </div>
                        <div className={s.item}>
                           <p>$150</p>
                        </div>
                        <div className={s.item}>
                           <p>$100</p>
                        </div>
                     </div>
                     <div className={s.tRow} >
                        <div className={s.item}>
                           <p>5</p>
                        </div>
                        <div className={s.item}>
                           <p>$125</p>
                        </div>
                        <div className={s.item}>
                           <p>$75</p>
                        </div>
                        <div className={s.item}>
                           <p>$50</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>6</p>
                        </div>
                        <div className={s.item}>
                           <p>$125</p>
                        </div>
                        <div className={s.item}>
                           <p>$75</p>
                        </div>
                        <div className={s.item}>
                           <p>$50</p>
                        </div>
                     </div>
                     <div className={s.tRow}>
                        <div className={s.item}>
                           <p>7</p>
                        </div>
                        <div className={s.item}>
                           <p>$125</p>
                        </div>
                        <div className={s.item}>
                           <p>$75</p>
                        </div>
                        <div className={s.item}>
                           <p>$50</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>8</p>
                        </div>
                        <div className={s.item}>
                           <p>$125</p>
                        </div>
                        <div className={s.item}>
                           <p>$75</p>
                        </div>
                        <div className={s.item}>
                           <p>$50</p>
                        </div>
                     </div>
                     <div className={s.tRow}>
                        <div className={s.item}>
                           <p>9</p>
                        </div>
                        <div className={s.item}>
                           <p>$63</p>
                        </div>
                        <div className={s.item}>
                           <p>$38</p>
                        </div>
                        <div className={s.item}>
                           <p>$25</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>10</p>
                        </div>
                        <div className={s.item}>
                           <p>$63</p>
                        </div>
                        <div className={s.item}>
                           <p>$38</p>
                        </div>
                        <div className={s.item}>
                           <p>$25</p>
                        </div>
                     </div>
                     <div className={s.tRow}>
                        <div className={s.item}>
                           <p>11</p>
                        </div>
                        <div className={s.item}>
                           <p>$63</p>
                        </div>
                        <div className={s.item}>
                           <p>$38</p>
                        </div>
                        <div className={s.item}>
                           <p>$25</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>12</p>
                        </div>
                        <div className={s.item}>
                           <p>$63</p>
                        </div>
                        <div className={s.item}>
                           <p>$38</p>
                        </div>
                        <div className={s.item}>
                           <p>$25</p>
                        </div>
                     </div>
                     <div className={s.tRow}>
                        <div className={s.item}>
                           <p>13</p>
                        </div>
                        <div className={s.item}>
                           <p>$63</p>
                        </div>
                        <div className={s.item}>
                           <p>$38</p>
                        </div>
                        <div className={s.item}>
                           <p>$25</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>14</p>
                        </div>
                        <div className={s.item}>
                           <p>$63</p>
                        </div>
                        <div className={s.item}>
                           <p>$38</p>
                        </div>
                        <div className={s.item}>
                           <p>$25</p>
                        </div>
                     </div>
                     <div className={s.tRow}>
                        <div className={s.item}>
                           <p>15</p>
                        </div>
                        <div className={s.item}>
                           <p>$63</p>
                        </div>
                        <div className={s.item}>
                           <p>$38</p>
                        </div>
                        <div className={s.item}>
                           <p>$25</p>
                        </div>
                     </div>
                     <div className={s.tRow} style={{ background: mainBGColor }}>
                        <div className={s.item}>
                           <p>16</p>
                        </div>
                        <div className={s.item}>
                           <p>$63</p>
                        </div>
                        <div className={s.item}>
                           <p>$38</p>
                        </div>
                        <div className={s.item}>
                           <p>$25</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}
export default PrizePool;
