export const getPrivacyPolicyLink = (lang?: unknown) => {
   switch (lang) {
      case 'fr':
         return 'https://app.passport.gg/privacy-policy'
      case 'ru':
         return 'https://app.passport.gg/privacy-policy'
      default:
         return 'https://app.passport.gg/privacy-policy'
   }
}


export const getCookiesPolicyLink = (lang?: unknown) => {
   switch (lang) {
      case 'fr':
         return 'https://app.passport.gg/cookie-policy'
      case 'ru':
         return 'https://app.passport.gg/cookie-policy'
      default:
         return 'https://app.passport.gg/cookie-policy'
   }
}

export const getTermsLink = (lang?: unknown) => {
   switch (lang) {
      case 'fr':
         return 'https://passport.gg/fr/tou-fr/'
      case 'ru':
         return 'https://passport.gg/ru/tou-ru/ '
      case 'rule':
         return 'https://app.passport.gg/terms-of-use'
      default:
         return 'https://passport.gg/tou/'
   }
}
//https://asia.passport.gg/rules/rules.pdf

export const getRulebookLink = (lang?: unknown) => {
   switch (lang) {
      case 'fr':
         return '/rulebook-asia'
      case 'ru':
         return '/rulebook-asia'
      case 'rule':
         return '/rulebook-asia'
      default:
         return '/rulebook-asia'
   }
}
