require("dotenv").config();

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const API_URL = process.env.REACT_APP_API_URL;
export const GTM_ID = process.env.REACT_APP_GTM_ID;

export const STEAM_GAMES_CODES = {
  "cs-go": 730,
  dota2: 570,
};

export const INTERFACE_LANGUAGES = [
  "en",
  "ru",
  /* 'pt',
   'fr',
   'es', */
];

export const INTERFACE_LANGUAGES_OPTIONS = [
  {
    label: "English",
    value: "en",
  },
  {
    label: "Русский",
    value: "ru",
  },
  {
    label: "Português",
    value: "pt",
  },
  {
    label: "Français",
    value: "fr",
  },
  {
    label: "Español",
    value: "es",
  },
];

export const LANGUAGE_SPEAK_OPTIONS = [
  {
    label: "German",
    value: "de",
  },
  {
    label: "English",
    value: "en",
  },
  {
    label: "French",
    value: "fr",
  },
  {
    label: "Italian",
    value: "it",
  },
  {
    label: "Portuguese",
    value: "pt",
  },
  {
    label: "Russian",
    value: "ru",
  },
  {
    label: "Spanish",
    value: "es",
  },
  {
    label: "Ukrainian",
    value: "ua",
  },
];

export const SOCIAL_LINKS = {
  instagram: "https://www.instagram.com/PassportGG",
  facebook: "https://www.facebook.com/PassportGG",
  twitter: "https://twitter.com/PassportGG",
  telegram: "https://t.me/PassportGG",
  vk: "https://vk.com/passportgg",
};

export const ROUTER = {
  homepage: "/",
  notFound: "/404",
  login: "/login",
  messenger: "/messenger",
  rules: "/rules_AOCH2022",
  schedule: "/schedule_AOCH2022",
  prize_pool: "/prize_pool_AOCH2022",
  rulebook_asia: "/rulebook-asia",
  emailConfirm: "/email/confirm",
};

export const TYPE_OPTIONS = [
  {
    label: "battle.types.city",
    value: "city",
  },
  {
    label: "battle.types.country",
    value: "country",
  },
  {
    label: "battle.types.community",
    value: "community",
  },
  {
    label: "battle.types.company",
    value: "company",
  },
];

export const TEAM_GAMES = [
  "cs-go",
  "dota2",
  "pes-mobile",
  "cod-mobile",
  "pubg-mobile",
];

export const COUNTRY_LIST = {
  AF: "Afghanistan",
  // AZ: "Azerbaijan",
  BH: "Bahrain",
  BD: "Bangladesh",
  BT: "Bhutan",
  BN: "Brunei",
  KH: "Cambodia",
  CN: "China",
  TW: "Chinese Taipei",
  HK: "Hong Kong, China",
  IN: "India",
  ID: "Indonesia",
  IR: "Iran",
  IQ: "Iraq",
  JP: "Japan",
  JO: "Jordan",
  KZ: "Kazakhstan",
  KR: "Korea",
  KW: "Kuwait",
  KG: "Kyrgyzstan",
  LA: "Laos",
  LB: "Lebanon",
  MO: "Macau, China",
  MY: "Malaysia",
  MV: "Maldives",
  MN: "Mongolia",
  MM: "Myanmar",
  NP: "Nepal",
  PK: "Pakistan",
  PS: "Palestine",
  PH: "Philippines",
  QA: "Qatar",
  SA: "Saudi Arabia",
  SG: "Singapore",
  LK: "Sri Lanka",
  SY: "Syria",
  TJ: "Tajikistan",
  TH: "Thailand",
  TM: "Turkmenistan",
  AE: "United Arab Emirates",
  UZ: "Uzbekistan",
  VN: "Vietnam",
};
