import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";

import {
  ButtonRow,
  CheckBox,
  Container,
  Input,
  Select,
  ContentBox,
  CloseRegModal,
} from "../../components/UI";
import store from "../../redux/store";
import {
  hideAuthModal,
  showAuthBlockedModal,
  showAuthModal,
  showRegisterModal,
  userOnline,
} from "../../redux/actions/auth";
// import {ROUTER} from "../../config";
import { API, API_ROUTER } from "../../api";

import { toast } from "react-toastify";
import Flag from "react-world-flags";
import { getUserData } from "../../redux/actions/user";
import { Box, Button, LinearProgress } from "@material-ui/core";
import {
  getCookiesPolicyLink,
  getPrivacyPolicyLink,
  getTermsLink,
  getUrlParams,
  getValueFromSelect,
  isEmailValid,
  isFieldEmpty,
  isPasswordValid,
  isSelectEmpty,
} from "../../helpers";
import PublicHeader from "../../components/header-public";
import { Styled } from "./style";

import Countries from "./countriesList";
// images
import picBeInvolved from "./img/be-involved.png";
import decorationDots from "./img/dots";
import rotatedDots from "./img/dotsRotated";
import titleArrows from "./img/titleArrows";
//flags
// import countryFlag from "../../img/img.png";

// import trImage02 from "./img/triple-02.png";
// import trImage03 from "./img/triple-03.png";
import countryFlag from "./img/towns.jpg";

import trImage02 from "./img/competition.jpg";
import trImage03 from "./img/sky.jpg";

import schoolImage from "./img/school.png";
import communityImage from "./img/Frame-17.jpg";
import allianceLogo from "../../img/logo-big.png";
import fc from "../../img/fc.png";
import inst from "../../img/inst.png";
import tw from "../../img/twitter.png";
import dots2 from "../../img/circle2.png";

import styles from "./styles.module.scss";
import circle from "../../img/circle.png";

// games pics
import gFortnite from "./img/games/game-fortnite.png";
import gDota from "./img/games/game-dota.png";
import gConstellation from "./img/games/Constellation.png";
import gLESF from "./img/games/LESF.png";
import gNHA from "./img/games/NHA.png";
import bigBang from "../../img/BBMV.png";
import iesfColored from "./img/iesfColored.png";

import gPassport from "./img/games/passport.png";
import gClashC from "./img/games/clashClans.jpg";

// import gClashC from "./img/games/Clash-of-Clans.png";
import country from "./img/noFlag.jpg";
import logoLG from "../../img/big-logo.png";

import gDota2 from "./img/games/Dota2.png";
import gCricket from "../../img/real.jpg";
import gPUBG from "./img/games/PUBG.png";
import eFootbal from "../../img/E-football.png";
import eFootbalSquare from "../../img/e-football.jpg";
import gCs from "./img/games/game-csgo.png";
import gOverwatch from "./img/games/game-overwatch.png";
import gFifa from "./img/games/games-fifa.png";
import gLeague from "./img/games/games-league-legends.png";
import gCOD from "./img/games/games-call-of-duty.png";
import gValor from "./img/games/games-arenavalor.png";
import gRBS from "./img/games/games-rainbowsix.png";
import gHS from "./img/games/games-heartstone.png";
import gSC2 from "./img/games/games-startcraft.png";
import gRocket from "./img/games/games-rocket-league.png";
import gApex from "./img/games/games-apex-legend.png";
import gPubg from "./img/games/game-battleground.png";
import gMTG from "./img/games/games-mtg-arena-1.png";
import gMap from "./img/map_east.jpg";
import videoBigBang from "./video/IEFS_ASIA OPEN-Horizontal.mp4";
import modal from "./modal.module.scss";
//карты гугл
/* import { Map } from '../../components/Map'; */
/* import { API_KEY } from '../../App'; */
//переменная окружения из файла .env
const images = [
  {
    original: communityImage,
    thumbnail: communityImage,
  },

  {
    original: trImage03,
    thumbnail: trImage03,
  },
  {
    original: communityImage,
    thumbnail: communityImage,
  },
  {
    original: trImage03,
    thumbnail: trImage03,
  },
  {
    original: communityImage,
    thumbnail: communityImage,
  },

  {
    original: trImage03,
    thumbnail: trImage03,
  },
  {
    original: communityImage,
    thumbnail: communityImage,
  },
  {
    original: trImage03,
    thumbnail: trImage03,
  },
];
class Homepage extends Component {
  state = {
    dataLoaded: false,
    googleMapsLoaded: false,
    userLocation: true,
    landingData: {},
    values: {
      firstName: "",
      username: "",
      password: "",
      country: [],
    },
    errors: {
      firstName: "",
      username: "",
      password: "",
      country: "",
      gdpr: "",
    },
    gdprChecked: false,
    marketingChecked: false,
    countryName: "",
    countryFlag: countryFlag,
    pathname: "",
    URLpathname: "",
  };

  static getDerivedStateFromProps(props, state) {
    const { userData } = props;
    const { dataLoaded } = state;
    if (userData && !dataLoaded) {
      state.dataLoaded = true;
      return state;
    }
    return null;
  }
  componentDidMount() {
    const domain = window.location.hostname.split(".")[0];
    this.onScriptLoad();
    this.getLocation().catch((err) => console.log(err));
    API.request({
      ...API_ROUTER.landing.getLanding,
      pathKeys: {
        landingIrl: domain,
      },
    })
      .then((res) => this.setState({ landingData: res }))
      .catch((err) => console.log(err));
    this.mapLauncher();
    this.getGeoInfo();
  }

  mapLauncher() {
    const { googleMapsLoaded, userLocation } = this.state;
    if (!googleMapsLoaded || !userLocation) {
      setTimeout(() => {
        this.mapLauncher();
      }, 100);
    } else {
      this.initMap();
    }
  }

  getLocation = async () => {
    let userIP = "";

    await API.request({
      ...API_ROUTER.external.getIpAddress,
      externalRequest: true,
    })
      .then((ip) => (userIP = ip))
      .catch((err) => console.log(err));

    API.request({
      ...API_ROUTER.public.getLocationByIp,
      pathKeys: { ip: userIP },
    })
      .then((res) => this.setState({ userLocation: res }))
      .catch((err) => console.log(err));
  };

  onScriptLoad = () => {
    const { google } = window;
    if (!google) {
      setTimeout(() => {
        this.onScriptLoad();
      }, 100);
    } else {
      this.setState({ googleMapsLoaded: !this.state.googleMapsLoaded });
    }
  };

  onInputChange = (e) => {
    const { values } = this.state;
    values[e.target.name] = e.target.value;

    this.setState({ values });
  };

  onSelectChange = (value) => {
    const { values } = this.state;
    values.country = value;

    this.setState({ values });
  };

  onCheckboxChange = (e) => {
    if (e.target.name === "gdpr")
      this.setState({ gdprChecked: !this.state.gdprChecked });

    if (e.target.name === "marketing")
      this.setState({ marketingChecked: !this.state.marketingChecked });
  };

  signUp = (e) => {
    e.preventDefault();

    const URLParams = getUrlParams();
    const { values, errors, gdprChecked, marketingChecked } = this.state;
    // const {history} = this.props;

    // validate
    errors.firstName = isFieldEmpty(values.firstName);
    errors.username = isEmailValid(values.username);
    errors.password = isPasswordValid(values.password);
    errors.country = isSelectEmpty(values.country);
    gdprChecked ? (errors.gdpr = "") : (errors.gdpr = "This field is required");
    this.setState({ errors });

    // send request if valid
    if (!Object.values(errors).some((value) => value.length)) {
      const params = {
        ...API_ROUTER.auth.registerEvent,
        pathKeys: {
          eventName: "platform-beta",
        },
        data: {
          ...values,
          country: getValueFromSelect(values.country),
          gdpr: gdprChecked,
          marketing: marketingChecked,
          token: "fake",
        },
      };

      // get referral
      if (URLParams.ref) {
        params.data.referral = URLParams.ref;
      }

      API.request(params, true)
        .then(({ token, refreshToken }) => {
          // store.dispatch(showAuthBlockedModal());
          // store.dispatch(showAuthModal());
        })
        .catch((err) => {
          let errorMessage = null;
          const { errors } = this.state;

          if (err.data && err.data.errors) {
            if (err.data.errors.username) {
              errorMessage = `The username ${this.state.values.username} already exists. Please sign in!`;
              errors.username = "User already exists";
              // store.dispatch(showAuthModal());
            }
          }

          this.setState({ errors });
          if (errorMessage) {
            toast.error(errorMessage);
          }
        });
    }
  };

  initMap() {
    const { userLocation } = this.state;
    const cords = { lat: userLocation.lat, lng: userLocation.lon };

    const map = new window.google.maps.Map(document.getElementById("map"), {
      center: cords,
      zoom: 9,
      streetViewControl: false,
      mapTypeControl: false,
    });

    new window.google.maps.Marker({
      position: cords,
      map: map,
    });

    window.map = map;
  }

  renderMap = () => {
    const { googleMapsLoaded, userLocation } = this.state;

    if (!googleMapsLoaded && !userLocation) return <LinearProgress />;

    return (
      <Styled.MapContainer>
        <div id="map" />
      </Styled.MapContainer>
    );
  };
  //////////////////////////////////////////определение страны
  getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        this.setState({
          countryName: data.country_name,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  checkCountry = (country) => {
    switch (country) {
      case "Russia":
        {
          this.setState({
            /* countryFlag: RussianFlag, */
          });
        }

        break;

      default:
        break;
    }
  };
  //////////////////////////////////////////
  renderLanding() {
    const { userLocation, values, errors, landingData } = this.state;
    const { countriesList, intl, history, interfaceLang } = this.props;

    ////Карты гугл
    const defaultCenter = {
      lat: 30.745,
      lng: 110,
    };

    const languageDataLanding = (data) => {
      if (Object.keys(landingData).length === 0) {
        return;
      }
      const result = Object.values(
        data?.filter((lang) =>
          lang.hasOwnProperty(interfaceLang.toUpperCase())
        )[0]
      )[0];

      return result;
    };

    return (
      <>
        <PublicHeader
          landingData={landingData}
          languageDataLanding={languageDataLanding}
        />
        <CloseRegModal
          className={modal.modal__wrapper}
          isTransparent={true}
          open={true}
        >
          <div className={modal.modal}>
            <img
              className={modal.modal__image}
              src={logoLG}
              alt="modal__image"
            />

            <div className={modal.modal__title}>
              <p className={modal.modal__title__header}>Dear Friends!</p>
              <p className={modal.modal__title__header}>
                The registration for the competition is closed! If you have
                registered, you will recieve soon an email with next steps.
                Matches schedule will be soon available here, as well as in the
                competition system, where you can login with button below.
              </p>
              <p className={modal.modal__title__header}>GL HF</p>
              <div className={modal.modal__title__buttons}>
                <div className={modal.button}>
                  <div
                    className={modal.buttonLogin}
                    onClick={() =>
                      (window.location.href = "https://bigbang.passport.gg/")
                    }
                  >
                    <FormattedMessage id="global.buttons.login" />
                  </div>
                </div>

                <div className={modal.button}>
                  <div
                    className={modal.buttonLogin}
                    onClick={() =>
                      (window.location.href =
                        "https://about.passport.gg/gamersupport")
                    }
                  >
                    HELP
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CloseRegModal>

        <Styled.BeInvolved>
          <div className="dots-decorator">
            <Container>
              <div className="row">
                <div className="col">
                  <h3 className={styles.title}>
                    {languageDataLanding(landingData?.textBlockAbout1) ? (
                      languageDataLanding(landingData?.textBlockAbout1)
                    ) : (
                      <FormattedMessage id="homepage.beInvolved.title" />
                    )}
                  </h3>
                  <div className={styles.description}>
                    {languageDataLanding(landingData?.textBlockAbout2) ? (
                      languageDataLanding(landingData?.textBlockAbout2)
                    ) : (
                      <FormattedMessage id="homepage.beInvolved.p1" />
                    )}
                  </div>
                  <a className={styles.link} href={`/schedule_AOCH2022`}>
                    {languageDataLanding(landingData?.textBlockAbout3) ? (
                      languageDataLanding(landingData?.textBlockAbout3)
                    ) : (
                      <FormattedMessage id="homepage.beInvolved.linkSchedule" />
                    )}
                  </a>
                  <ButtonRow direction="left">
                    <Button
                      className={styles.button}
                      onClick={() => {
                        // store.dispatch(showAuthModal());
                        // store.dispatch(showRegisterModal());
                      }}
                    >
                      {languageDataLanding(landingData?.textBlockButtons1) ? (
                        languageDataLanding(landingData?.textBlockButtons1)
                      ) : (
                        <FormattedMessage id="global.buttons.signUp" />
                      )}
                    </Button>
                  </ButtonRow>
                </div>
                <div>
                  <div className="imgLogo">
                    <div className="item">
                      <a target="_blank" href="https://clashofclans.com">
                        <img src={gClashC} alt="be involved" />
                      </a>
                    </div>
                    <div className="item">
                      <a target="_blank" href="https://www.dota2.com/home">
                        <img
                          style={{ padding: "40px", marginLeft: "-25px" }}
                          src={gDota2}
                          alt="be involved"
                        />
                      </a>
                    </div>
                    <div className="item">
                      <a target="_blank" href="https://www.nautilusmobile.com/">
                        <img
                          src={gCricket}
                          className="imgB"
                          alt="be involved"
                        />
                      </a>
                    </div>
                    <div className="item">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.pubgmobile.com"
                      >
                        <img
                          onClick={() =>
                            (window.location.href =
                              "https://www.pubgmobile.com")
                          }
                          style={{ width: "80%" }}
                          src={gPUBG}
                          className="imgPUBG"
                          alt="be involved"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="item">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.konami.com/efootball/en-us/"
                    >
                      <img
                        onClick={() =>
                          (window.location.href =
                            "https://www.konami.com/efootball/en-us/")
                        }
                        src={eFootbal}
                        className="imgPUBG"
                        alt="eFootbal"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </Styled.BeInvolved>
        <Styled.Line>
          {languageDataLanding(landingData?.textBlockAbout4) ? (
            <div>{languageDataLanding(landingData?.textBlockAbout4)}</div>
          ) : (
            <FormattedMessage id="homepage.landingOurCountry" tagName="div" />
          )}
        </Styled.Line>

        <Styled.MapArea>
          <div className="dots-decorator">
            <Container>
              <div className="row">
                <div className="col">
                  {/* <h3 className={styles.title}>
                    <FormattedMessage id="homepage.rockArea.landing" />
                  </h3> */}

                  <div className="linkContainer">
                    <div className={styles.description}>
                      {languageDataLanding(landingData?.textBlockAbout5)
                        ? languageDataLanding(landingData?.textBlockAbout5)
                        : "Vivamus mollis elit quis odio porttitor, a semper lectus placerat. Donec viverra turpis quis leo tincidunt, at consectetur nisi sagittis. Donec eu mollis metus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Morbi tristique accumsan orci, vel efficitur massa ullamcorper sed. Fusce dapibus diam ligula, quis ultrices risus auctor ut. Suspendisse vehicula tincidunt ante at venenatis. Aenean non turpis  nec lacus congue malesuada nec at magna. Aliquam accumsan  lorem nibh, in gravida augue placerat id. Nam bibendum, dolor quis faucibus vulputate, leo nisi rhoncus ex, ac tristique ligula mi id lectus. Suspendisse ac fringilla erat, a fringilla tortor. Aenean eget luctus mi, ut efficitur felis. In vestibulum et metus sed posuere. Etiam vulputate porttitor fermentum. Integer quis felis ut neque pretium lobortis. Donec est nibh, scelerisque at urna sit amet, consequat gravida nisl."}
                    </div>
                  </div>
                  <div className="linkContainer" style={{ marginTop: "20px" }}>
                    <div className={styles.description}>
                      <h3>
                        {/* Official name: */}
                        {/* <a>
                          <p>IESF WORLD ESPORTS</p>{" "}
                        </a> */}
                        {languageDataLanding(landingData?.textBlockAbout6)
                          ? languageDataLanding(landingData?.textBlockAbout6)
                          : "Official name: -"}
                      </h3>
                      <br />
                      <h3>
                        {/* President name: */}
                        {/* <a>
                          <p>BigBangSports</p>
                        </a> */}
                        {languageDataLanding(landingData?.textBlockAbout7)
                          ? languageDataLanding(landingData?.textBlockAbout7)
                          : "President name: -"}
                      </h3>{" "}
                      <br />
                      <h3>
                        {/* Contact us at : */}
                        {/* <a>
                          <p>BigBangSports@com</p>
                        </a> */}
                        {languageDataLanding(landingData?.textBlockAbout8)
                          ? languageDataLanding(landingData?.textBlockAbout8)
                          : "Contact us at : -"}
                      </h3>{" "}
                      <br />
                      <h3>
                        {/* Web-site: */}
                        {/* <a>
                          <p>BigBangSports.gg</p>
                        </a> */}
                        {languageDataLanding(landingData?.textBlockAbout9)
                          ? languageDataLanding(landingData?.textBlockAbout9)
                          : "Web-site: -"}
                      </h3>
                      <br />
                      <h3>
                        {/* Email: */}
                        {/* <a>
                          <p>BigBangSports@Emal.com</p>
                        </a> */}
                        {languageDataLanding(landingData?.textBlockAbout10)
                          ? languageDataLanding(landingData?.textBlockAbout10)
                          : " Email: -"}
                      </h3>{" "}
                      <br />
                      <h3>
                        {/* Adress: */}
                        {/* <p>First city, Second street</p> */}
                        {languageDataLanding(landingData?.textBlockAbout11)
                          ? languageDataLanding(landingData?.textBlockAbout11)
                          : "Adress: -"}
                      </h3>{" "}
                      <br />
                    </div>
                  </div>
                </div>

                <div className="images">
                  <img src={iesfColored}></img>
                  {!!landingData?.landingLogo && (
                    <img
                      src={
                        !!landingData?.landingLogo && landingData?.landingLogo
                      }
                    ></img>
                  )}
                  {!landingData?.landingLogo && (
                    <img
                      src={
                        !landingData?.landingLogo && landingData?.countryFlag
                      }
                    ></img>
                  )}
                </div>
              </div>
            </Container>
          </div>
        </Styled.MapArea>
        {/* <Container style={{ marginBottom: "50px", maxWidth: "1000px" }}>
        <ImageGallery
          items={images}
          showBullets={true}
          showThumbnails={true}
          showNav={true}
          autoPlay={true}
          showFullscreenButton={false}
          thumbnailPosition={"bottom"}
        />
      </Container> */}
        <Styled.Line></Styled.Line>
        <Styled.Games>
          <Container>
            <h3 className={styles.titleFull}>
              {languageDataLanding(landingData?.textBlockAbout12) ? (
                languageDataLanding(landingData?.textBlockAbout12)
              ) : (
                <FormattedMessage id="homepage.organisersPartners.title" />
              )}
            </h3>
            <div className="mainUpperContainer">
              <div className="rowContainer">
                <div className="containerTitle">
                  <div className="title">
                    {languageDataLanding(landingData?.textBlockAbout13) ? (
                      languageDataLanding(landingData?.textBlockAbout13)
                    ) : (
                      <FormattedMessage id="homepage.organisersPartners.eventOrganisers" />
                    )}
                  </div>
                </div>
                <div className="container">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/BigBangSports.gg"
                  >
                    <div className="imgContainer">
                      <img src={bigBang} alt="NHA" />
                    </div>
                  </a>
                </div>
              </div>

              <div className="rowContainer small-logo">
                <div className="containerTitle">
                  <div className="title">
                    {languageDataLanding(landingData?.textBlockAbout14) ? (
                      languageDataLanding(landingData?.textBlockAbout14)
                    ) : (
                      <FormattedMessage id="homepage.organisersPartners.eventLicensed" />
                    )}
                  </div>
                </div>
                <div className="container">
                  <a target="_blank" href="https://iesf.org">
                    <div className="imgContainer">
                      <img src={iesfColored} alt="LESF" />
                    </div>
                  </a>
                </div>
              </div>

              <div className="rowContainer">
                <div className="containerTitle">
                  <div className="title">
                    {languageDataLanding(landingData?.textBlockAbout15) ? (
                      languageDataLanding(landingData?.textBlockAbout15)
                    ) : (
                      <FormattedMessage id="homepage.organisersPartners.technicalPartner" />
                    )}
                  </div>
                </div>
                <div className="container">
                  <div className="imgContainer ">
                    <a target="_blank" href="https://about.passport.gg/">
                      <img src={gPassport} alt="Passport" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Container>
          <img src={circle} className="dots" />
        </Styled.Games>
        <Styled.Line>
          {languageDataLanding(landingData?.textBlockAbout16) ? (
            <div>{languageDataLanding(landingData?.textBlockAbout16)}</div>
          ) : (
            <FormattedMessage id="homepage.blueLine2" tagName="div" />
          )}
        </Styled.Line>
        <Styled.School>
          <div className="dots-decorator">
            <Container>
              <div className="row">
                <div className="col">
                  <h3 className={styles.titleFullLeft}>
                    {languageDataLanding(landingData?.textBlockAbout17) ? (
                      <div>
                        {languageDataLanding(landingData?.textBlockAbout17)}
                      </div>
                    ) : (
                      <FormattedMessage id="homepage.dedicatedRatings.title1" />
                    )}
                  </h3>

                  <div className={styles.text}>
                    <div className={styles.text}>
                      {languageDataLanding(landingData?.textBlockAbout18) ? (
                        languageDataLanding(landingData?.textBlockAbout18)
                      ) : (
                        <FormattedMessage id="homepage.dedicatedRatings.title2" />
                      )}
                    </div>
                    <ul>
                      <FormattedMessage
                        id="homepage.dedicatedRatings.p3"
                        tagName="li"
                      />
                      <FormattedMessage
                        id="homepage.dedicatedRatings.p2"
                        tagName="li"
                      />
                      <FormattedMessage
                        id="homepage.dedicatedRatings.p22"
                        tagName="li"
                      />
                      <FormattedMessage
                        id="homepage.dedicatedRatings.p5"
                        tagName="li"
                      />
                      <FormattedMessage
                        id="homepage.dedicatedRatings.p4"
                        tagName="li"
                      />
                    </ul>
                  </div>

                  <div className={styles.text}>
                    <p>
                      <a href="/rules_AOCH2022#levels">
                        {languageDataLanding(landingData?.textBlockAbout19)
                          ? languageDataLanding(landingData?.textBlockAbout19)
                          : "The event includes 3 levels of competition, which allow any gamer to get a chance to become a champion. Gather your team and choose one of the levels:"}
                      </a>
                    </p>
                    <ul>
                      <FormattedMessage
                        id="homepage.dedicatedRatings.p6"
                        tagName="li"
                      />
                      <FormattedMessage
                        id="homepage.dedicatedRatings.p7"
                        tagName="li"
                      />
                      <FormattedMessage
                        id="homepage.dedicatedRatings.p8"
                        tagName="li"
                      />
                    </ul>
                  </div>
                </div>
                <div className="col">
                  <img
                    src={communityImage}
                    alt="Special ratings: be the best in your community!"
                  />
                </div>
              </div>
            </Container>
          </div>
        </Styled.School>

        <Styled.WorldLine>
          <div className="map">
            <Container>
              <div className={styles.row}>
                <div className={styles.colCenter}>
                  <img
                    className={styles.logo}
                    src={allianceLogo}
                    alt="e-sport"
                  />
                  <div className={styles.rowSoc}>
                    <a
                      href="https://www.facebook.com/BigBangSports.gg"
                      target="_blank"
                      className={styles.link}
                    >
                      <img src={fc} />
                    </a>
                    <a
                      href="https://www.instagram.com/bigbangsports.gg/"
                      target="_blank"
                      className={styles.link}
                    >
                      <img src={inst} />
                    </a>

                    <a
                      href="https://twitter.com/BigBangSports_"
                      target="_blank"
                      className={styles.link}
                    >
                      <img src={tw} />
                    </a>
                  </div>
                </div>
                <div className={styles.col}>
                  <h3 className={styles.titleFullLeft}>
                    <span className={styles.whiteText}>
                      {languageDataLanding(landingData?.textBlockAbout21) ? (
                        languageDataLanding(landingData?.textBlockAbout21)
                      ) : (
                        <FormattedMessage id="homepage.blueLine4.title" />
                      )}
                    </span>
                  </h3>
                  <div className={styles.text}>
                    <span className={styles.whiteText}>
                      {languageDataLanding(landingData?.textBlockAbout22) ? (
                        languageDataLanding(landingData?.textBlockAbout22)
                      ) : (
                        <FormattedMessage id="homepage.blueLine4.message" />
                      )}
                    </span>
                  </div>
                  <div className={styles.buttonContainer}>
                    <button
                      className={styles.gradientButton}
                      onClick={() =>
                        (window.location.href = "https://bigbang.passport.gg/")
                      }
                    >
                      {languageDataLanding(landingData?.textBlockButtons5) ? (
                        languageDataLanding(landingData?.textBlockButtons5)
                      ) : (
                        <FormattedMessage id="homepage.blueLine4.button" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </Styled.WorldLine>
        <Styled.Games>
          <Container>
            <div className={styles.titleFull}>
              {languageDataLanding(landingData?.textBlockAbout23) ? (
                languageDataLanding(landingData?.textBlockAbout23)
              ) : (
                <FormattedMessage id="homepage.topGames" />
              )}
            </div>
            <div className={styles.imgGrid}>
              <div className={styles.item}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.konami.com/efootball/en-us/"
                >
                  <img
                    src={eFootbalSquare}
                    alt="eFootbal"
                    className="imgGames"
                    style={{ padding: "35px" }}
                  />
                </a>
              </div>
              <div className={styles.item}>
                <a target="_blank" href="https://clashofclans.com">
                  <img
                    src={gClashC}
                    alt="Clash-of-Clans"
                    className="imgGames"
                  />
                </a>
              </div>
              <div className={styles.item}>
                <a target="_blank" href="https://www.dota2.com/home">
                  <img
                    src={gDota2}
                    alt="clashroyale_logo"
                    className="imgGames"
                    style={{ padding: "50px" }}
                  />
                </a>
              </div>
              <div className={styles.item}>
                <a target="_blank" href="https://www.nautilusmobile.com/">
                  <img src={gCricket} alt="Critical Ops" className="imgGames" />
                </a>
              </div>
              <div className={styles.item}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.pubgmobile.com"
                >
                  <img
                    onClick={() =>
                      (window.location.href = "https://www.pubgmobile.com")
                    }
                    src={gPUBG}
                    alt="PUBG"
                    className="imgGames "
                    style={{ padding: "30px" }}
                  />
                </a>
              </div>
            </div>
          </Container>
          <img className="dots-decor" src={dots2} alt="bg" />
        </Styled.Games>
        <Styled.WorldLine>
          <div className="map">
            <Container>
              <div className={styles.titleFull}>
                <span className={styles.whiteText}>
                  {languageDataLanding(landingData?.textBlockAbout24) ? (
                    <a href={`/prize_pool_AOCH2022/ `}>
                      {languageDataLanding(landingData?.textBlockAbout24)}{" "}
                    </a>
                  ) : (
                    <>
                      <FormattedMessage id="homepage.register.title1" />
                      <a href={`/prize_pool_AOCH2022/ `}>
                        <FormattedMessage id="homepage.register.title.link" />
                      </a>
                      <FormattedMessage id="homepage.register.title2" />
                    </>
                  )}
                </span>
              </div>

              <div className={styles.textCenter}>
                {languageDataLanding(landingData?.textBlockAbout25) ? (
                  <a href={`/rules_AOCH2022/#country`}>
                    {languageDataLanding(landingData?.textBlockAbout25)}{" "}
                  </a>
                ) : (
                  <>
                    <FormattedMessage id="homepage.register.description" />
                    <a href={`/rules_AOCH2022/#country`}>
                      <FormattedMessage id="homepage.register.description.link" />
                    </a>
                  </>
                )}
              </div>

              <div className={styles.buttonContainerCenter}>
                <button
                  type="submit"
                  className={styles.gradientButton}
                  onClick={() => {
                    // store.dispatch(showRegisterModal());
                    // store.dispatch(showAuthModal());
                  }}
                >
                  {languageDataLanding(landingData?.textBlockAbout20) ? (
                    languageDataLanding(landingData?.textBlockAbout20)
                  ) : (
                    <FormattedMessage id="global.buttons.signUp_now" />
                  )}
                </button>
              </div>
            </Container>
          </div>
        </Styled.WorldLine>
      </>
    );
  }
  render() {
    return this.renderLanding();
  }
}

const mapStateToProps = (state) => {
  return {
    userOnline: state.userOnline,
    countriesList: state.countriesList,
    interfaceLang: state.interfaceLang,
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserData: () => dispatch(getUserData()),
    runUserOnline: () => dispatch(userOnline()),
    runDispatchHideAuthModal: () => dispatch(hideAuthModal()),
  };
};

export default injectIntl(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Homepage))
);
