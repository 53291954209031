import React from "react";
import { FormattedMessage } from "react-intl";

//color tchema
import { mainBGColor } from "../../total/const";

import s from "./rules.module.scss";
import SmollHeader from "../smoll-header";
import countryFlag from "../../img/triple01.jpg";

const Rules = (props) => {
  return (
    <>
      <SmollHeader />
      <div className={s.blockContainer}>
        <div className={`${s.contentContainer} ${s.textBalck}`}>
          <div className={s.textBalck}>
            <FormattedMessage id="pages.rules.mainTitle" tagName="h1" />
          </div>
          <FormattedMessage id="pages.rules.h1" tagName="h2" />
          <div className={s.linkContainer} style={{ textAlign: "left" }}>
            <FormattedMessage id="pages.rules.p1" tagName="p" />
            <a href="/rulebook-asia">
              <FormattedMessage id="pages.rules.p1.link" tagName="p" />
            </a>
          </div>
          <div>
            <b>Event overview</b>
          </div>
          <div>
            <p style={{ textAlign: "left" }}>
              Big Bang IeSF Asia Open Esports Championship is the major esports
              event run by Big Bang Media Ventures and authorized by
              International Esports Federation. The event consists of 15 online
              tournaments - three tournaments per each of the five featured
              games: Clash of Clans, Dota 2, eFootball, PUBG Mobile and Real
              Cricket.
            </p>
          </div>
          <br />
          <div className={s.linkContainer}>
            <FormattedMessage id="pages.rules.p2.first" tagName="p" />
            <a href="/rulebook-asia">
              <FormattedMessage id="pages.rules.p2.link" tagName="p" />
            </a>
            <FormattedMessage id="pages.rules.p2.last" tagName="p" />
          </div>
        </div>
      </div>
      <div
        className={s.blockContainer}
        style={{ background: mainBGColor, color: "white" }}
      >
        <div
          className={`${s.contentContainer} ${s.text}`}
          style={{ color: "white" }}
        >
          <FormattedMessage id="pages.rules.title1" tagName="h2" />
          <FormattedMessage id="pages.rules.p3" tagName="p" />
        </div>
      </div>
      <div id="country" className={`${s.contentContainer} ${s.textBalck}`}>
        <div className={s.blockContainer}>
          <div className={s.title}>
            <FormattedMessage id="pages.rules.title2" tagName="h2" />
          </div>
          <div className={s.flexContainer}>
            <div className={s.imgContainer}>
              {/*<img src={countryFlag} alt="flags" />*/}
              <table>
                <tr>
                  <td>AFGHANISTAN</td>
                  <td>KAZAKHSTAN</td>
                  <td>PHILIPPINES</td>
                </tr>
                <tr>
                  <td>BAHRAIN</td>
                  <td>KOREA</td>
                  <td>QATAR</td>
                </tr>
                <tr>
                  <td>BANGLADESH</td>
                  <td>KUWAIT</td>
                  <td>SAUDI ARABIA</td>
                </tr>
                <tr>
                  <td>BRUNEI</td>
                  <td>KYRGYZSTAN</td>
                  <td>SINGAPORE</td>
                </tr>
                <tr>
                  <td>BHUTAN</td>
                  <td>LAOS</td>
                  <td>SRI LANKA</td>
                </tr>
                <tr>
                  <td>CAMBODIA</td>
                  <td>LEBANON</td>
                  <td>SYRIA</td>
                </tr>
                <tr>
                  <td>CHINA</td>
                  <td>MACAU, CHINA</td>
                  <td>CHINESE TAIPEI</td>
                </tr>
                <tr>
                  <td>HONG KONG, CHINA</td>
                  <td>MALAYSIA</td>
                  <td>TAJIKISTAN</td>
                </tr>
                <tr>
                  <td>INDIA</td>
                  <td>MALDIVES</td>
                  <td>THAILAND</td>
                </tr>
                <tr>
                  <td>INDONESIA</td>
                  <td>MONGOLIA</td>
                  <td>TURKMENISTAN</td>
                </tr>
                <tr>
                  <td>IRAN</td>
                  <td>MYANMAR</td>
                  <td>UAE</td>
                </tr>
                <tr>
                  <td>IRAQ</td>
                  <td>NEPAL</td>
                  <td>UZBEKISTAN</td>
                </tr>
                <tr>
                  <td>JAPAN </td>
                  <td>PAKISTAN</td>
                  <td>VIETNAM</td>
                </tr>
                <tr>
                  <td>JORDAN</td>
                  <td>PALESTINE</td>
                  <td></td>
                </tr>
              </table>
            </div>

            <div className={s.textContainer}>
              <div className={s.linkContainer}>
                <FormattedMessage id="pages.rules.p4.first" tagName="p" />
                <a href="/rulebook-asia">
                  <FormattedMessage id="pages.rules.p4.link" tagName="p" />
                </a>
                <FormattedMessage id="pages.rules.p4.last" tagName="p" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={s.limitations} id="eligibility">
        <h2>Player’s eligibility</h2>
        <p>
          Players should comply with the legislation and regulations applicable
          in their respective countries regarding the age, accessibility and
          other limitations that may influence the ability to join the
          competition.
        </p>
        <p>
          It’s also forbidden to use third-party services (e.g. VPN) to access
          the event infrastructure in case if the country of residence doesn’t
          allow it.{" "}
        </p>
        <p>
          It’s strictly forbidden to provide inaccurate data (e.g. higher age,
          than real). In such case, Organizer reserves the right to withdraw the
          player and the team where he / she was playing with a 2 years ban in
          any official competitions, organized by the Event stakeholders
        </p>
      </div>
      <div
        className={`${s.blockContainer}`}
        style={{ background: mainBGColor }}
      >
        <div className={` ${s.imgBlock}`}>
          <div className={`${s.contentContainer}`}>
            <div
              id="levels"
              className={s.board}
              style={{ background: mainBGColor, color: "white" }}
            >
              <FormattedMessage id="pages.rules.title3" tagName="h2" />
              <FormattedMessage id="pages.rules.p5" tagName="p" />
              <ul style={{ color: "white" }}>
                <FormattedMessage id="pages.rules.li1" tagName="li" />
                <FormattedMessage id="pages.rules.li2" tagName="li" />
                <FormattedMessage id="pages.rules.li3" tagName="li" />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Rules;
