export const mainBGColor = `radial-gradient(104.65% 533.13% at 0.94% 53.84%, #CA2D4A 0%, #661024 44.13%, #CA2D4A 76.01%, #661024 100%)`;
//#3F317C

export const PrimaryTextColor = '#661024';
export const PrimaryBackgroundGradientColor = 'radial-gradient(104.65% 533.13% at 0.94% 53.84%, #CA2D4A 0%, #661024 44.13%, #CA2D4A 76.01%, #661024 100%)'

export const BTNColor = `white`;

export const BTNRegistrationColor = `rgb(155, 33, 29)`;
export const BTNRegistrationBorder = `2px solid rgb(242 199 104)`;

export const borderColor = `rgb(155, 33, 29)`;
//#D5CBFF
//#B9A9FF-используется чаще

export const bgColorLite = `rgb(155, 33, 29)`;
//#F8F6FF

export const bgColorText = `white`;

export const bgFooterColor = `black`;
//#201941
