import styled from "styled-components";
import { Container } from '../UI';
//color tchema
import { mainBGColor, borderColor, bgColorLite, bgFooterColor } from '../../total/const';

export const Styled = {
   Footer: styled.footer`
        background: ${bgFooterColor};
        padding: 4rem 0;
    `,
   Container: styled(Container)``,
   Row: styled.div`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        margin: 0 -15px;
     @media (max-width: 865px) {
       display: flex;
       flex-direction: column;
       justify-content: center;
       
     }
   `,
   Col: styled.div`
     img {
       max-height: 30px;
     }
     
        padding: 0 15px;

     @media (max-width: 865px) {
       margin-bottom: 4rem;
     }

     &:nth-child(2) {
       display: flex;
       flex-direction: column;
       justify-content: flex-start;
     }
     
     &:nth-child(3) {
       justify-content: space-between;
       display: flex;
       flex-direction: column;
       align-items: flex-end;
     }
     
     .text {
       font-size: 2rem;
       margin: 0;

       @media (max-width: 765px) {
         font-size: 1.8rem;
       }
       
       a {
         font-size: 2rem;
         text-decoration: underline;
         
         

         @media (max-width: 765px) {
           font-size: 1.8rem;
         }
         
         &:hover {
           text-decoration: none;
         }
       }
     }
     
     .right-content {
       max-width: 16.2rem;
       min-height: 100%;
       display: flex;
       flex-direction: column;
       justify-content: flex-start;
       a {
         color: white;
         
         .support {
           color: white;
         }
       }

       @media (max-width: 865px) {
         max-width: 100%;
         width: 100%;
         justify-content: center;
         display: flex;
         align-items: center;
       }
     }
     
     a {
       font-size: 2rem;
       text-decoration: none;
       transition: all 0.3s;

       @media (max-width: 765px) {
         font-size: 1.8rem;
       }
       
       &:hover {
         text-decoration: underline;
       }
     }

    `,
   NavItem: styled.div`
      .row-log {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        color: white;
        font-size: 20px;

        @media (max-width: 865px) {
          align-items: center;
        }
        
        img {
          margin-top: 20px;
        }
        
        a {
          margin-left: 10px;
        }
        
        p {
          display: flex;
          align-items: center;
          white-space: nowrap;
        }
      }
        & + & {
            margin-top: 16px;
        }

        a {
            font-weight: 500;
            font-size: 20px;
            text-decoration: none;
            color: #fff;

            &:hover, &focus {
                text-decoration: underline;
            }

          @media (max-width: 865px) {
            display: block;
            text-align: center;
            font-size: 1.6rem;
            justify-content: center;
            align-items: center;
          }
        }
     
        a *{
         text-align: center;
      }
    `,
   SocialTitle: styled.div`
        font-weight: bold;
        font-size: 2rem;
        margin-bottom: 2rem;
        line-height: 22px;
        text-align: left;
        margin-top: 2rem;
     color: white;

     @media (max-width: 865px) {
       text-align: center;
       margin-top: 4rem;
     }
        
    `,
   SocialLinks: styled.div`
        display: flex;
        align-items: center;
        justify-content: flex-end;
    `,
   SocialLink: styled.a`
        width: 24px;
        height: 24px;
        opacity: .6;
        transition: opacity .3s ease;
        
        &:hover, &:focus {
            opacity: 1;
        }
        
        & + & {
            margin-left: 20px;
        }
    `,
   Copy: styled.div`
        margin: 16px 0 0;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
    `
}
