import React from "react";
import { FormattedMessage } from "react-intl";

//color tchema
import { mainBGColor } from "../../total/const";

import s from "./prizePool.module.scss";
import SmollHeader from "../smoll-header";
import countryFlag from "../../img/triple01.jpg";

const RulesAsia = (props: any) => {
  return (
    <>
      <SmollHeader title="" />
      <div className={s.blockContainer}>
        <div className={`${s.contentContainer} ${s.textBalck}`}>
          <p style={{ fontWeight: 700, textAlign: "right" }}>
            Ver 1.01.04, last update 15th of December 2022. Subject to updates
            and correction, please check this page regularly to be updated
          </p>
          <h1 style={{ textAlign: "center" }}>
            IeSF Big Bang Asia Open Rulebook
          </h1>
          <div style={{ textAlign: "center" }}>
            <p style={{ textAlign: "center" }}>Table of contents</p>
          </div>
        </div>
        <ol className={s.blockContainer}>
          <li>
            <a href="#1">1. Introduction</a>
          </li>
          <li>
            <a href="#2">2. General Overview</a>
          </li>
          <li>
            <a href="#2.1">2.1 Definitions</a>
          </li>
          <li>
            <a href="#2.2">2.2 Prize money</a>
          </li>
          <li>
            <a href="#2.3">2.3 Rule Changes</a>
          </li>
          <li>
            <a href="#2.4">2.4 Confidentiality</a>
          </li>
          <li>
            <a href="#2.5">2.5 Participation Eligibility</a>
          </li>
          <li>
            <a href="#3">3. Championship structure</a>
          </li>
          <li>
            <a href="#4">4. Code of Conduct.</a>
          </li>
          <li>
            <a href="#5">5. Penalties</a>
          </li>
          <li>
            <a href="#6">6. Legal Aspects</a>
          </li>
          <li>
            <a href="#7">7. Personal data</a>
          </li>
          <li>
            <a href="#8">8. Media</a>
          </li>
          <li>
            <a href="#9"> 9. Disputes</a>
          </li>
        </ol>

        <div id="1" className={s.blockContainer}>
          <h2>1. Introduction</h2>
          <p>
            These Official Rules (“Rules”) of the IeSF Big Bang Asia Open
            (“Championship”) apply to each person participating in the
            Tournaments of the Championship (“Players” participating in
            “Tournaments”) and to all Championship officials. All Players must
            agree to abide by the conditions set forth in this document, with
            the understanding that any violation of the Rules may result in
            immediate disqualification and forfeiture of all prize money earned
            as determined by Championship organizers ("Organizers”). By
            competing in the Championship you agree to the following. The
            Championship Organizers (“Organizers”) in their sole discretion: (a)
            may update, amend, or supplement these Rules at any time, by
            publishing the updated version on the Tournament Hub, and (b) may
            interpret or apply these Rules by releasing notices, online
            postings, e-mails, or other electronic communications that provide
            instructions and guidance to the Players. By participating in the
            Tournaments, the Player accepts, agrees and undertakes to comply
            with the Rules.
          </p>
        </div>

        <div id="2" className={s.blockContainer}>
          <h2>2. General Overview</h2>
          <div id="2.1">
            <b>2.1 Definitions</b>
          </div>
          <p>
            Championship: refers to IeSF Big Bang Asia Open. Tournaments: refers
            to esports tournaments that take place during the Championship,
            including eFootball, Clash of Clans, Dota 2, PUBG Mobile and Real
            Cricket tournaments for Amateur, Semi-pro and Professional players.
            Organizers: refers to Big Bang Media Ventures and other parties
            authorized by them. Championship officials: judges, administrators,
            casters, managers and other persons appointed by Organizers to run
            the Championship. Player: refers to a physical person participating
            in the Tournament as a Participant; Participant: a Player (for
            eFootball and Real Cricket Tournaments) or a team of Players (for
            Clash of Clans, Dota 2 and PUBG Mobile Tournaments) duly registered
            to participate in a Tournament. Single Elimination: participants
            will compete in a bracket format where a participant is eliminated
            from competition after one (1) loss. Double Elimination:
            participants will compete in a bracket format using two brackets, a
            winner’s bracket (for participants without a loss) and a lower
            bracket (for participants with one (1) loss) where a participant is
            eliminated from competition after two (2) losses. Tournament Hub:
            Passport.gg, web competition platform where Tournaments
            registration, scheduling, event information, and match setup are
            available.
          </p>
          <div id="2.2">
            <b>2.2 Prize money</b>
          </div>
          <p>
            The total prize pool of the Championship is 100 000 (one hundred
            thousand) US dollars. Detailed prizepool distribution is described
            in the appropriate section. If a player qualifies for prizing, he /
            she will be contacted by Championship officials in order to begin
            prizing payment processing. If a team qualifies for prizing, team
            Players or team captains will be contacted in order to begin prizing
            payment processing. Teams must submit payment information within
            seven (7) days of the completion of the competition. Prize money
            will be paid out (withholding appropriate taxes) 90 days upon
            receipt of complete and correct payment information. Players shall
            execute any document which is deemed necessary for payment by
            Championship officials. Players are responsible for any and all
            costs and expenses associated with their prize that are not
            specifically set forth herein. International, national, state and
            local taxes, including possible VAT taxes, and any duties and
            similar costs which are associated with the receipt or use of any
            prizes are the sole responsibility of the Player. Championship
            officials are entitled, and Player hereby authorizes the
            Championship officials, to withhold (or cause there to be withheld,
            as the case may be) from any amounts otherwise due or payable as
            prize to Player such national, provincial, local or any other
            income, employment, or other taxes as may be required to be withheld
            pursuant to any applicable law or regulation.
          </p>

          <div id="2.3">
            <b>2.3 Rule Changes</b>
          </div>
          <p>
            Organizers reserve the right to amend, remove, or otherwise change
            the rules outlined in these Rules by publishing the updated version
            on the Tournament Hub. Organizers also reserve the right to make
            judgment on cases that are not explicitly supported or detailed in
            these Rules, or in extraordinary cases, judgements that may even go
            against these Rules to preserve fair play and sportsmanship. The
            Organizer has the right at any time to terminate the Championship by
            publishing the corresponding change on the Tournament Hub or
            otherwise publicly notify about such termination.
          </p>
          <div id="2.4">
            <b>2.4 Confidentiality</b>
          </div>
          <p>
            All content including protests, support tickets, discussions and/or
            any other correspondence with Organizers are deemed strictly
            confidential. The publication of such material is prohibited without
            prior written consent from Organizers. Publication of the
            aforementioned material without prior written consent may result in
            penalty points.
          </p>

          <div id="2.5">
            <b>2.5 Participation Eligibility</b>
          </div>
          <p>
            Players must be citizens or legal residents of the following
            countries in order to participate
          </p>
          <div id="country" className={`${s.contentContainer} ${s.textBalck}`}>
            <div className={s.blockContainer}>
              <div className={s.flexContainer}>
                <div className={s.imgContainer}>
                  {/*<img src={countryFlag} alt="flags" />*/}
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td>AFGHANISTAN</td>
                      <td>KAZAKHSTAN</td>
                      <td>PHILIPPINES</td>
                    </tr>
                    <tr>
                      <td>BAHRAIN</td>
                      <td>KOREA</td>
                      <td>QATAR</td>
                    </tr>
                    <tr>
                      <td style={{ color: "#410713", fontWeight: "bold" }}>
                        BANGLADESH *
                      </td>

                      <td>KUWAIT</td>
                      <td>SAUDI ARABIA</td>
                    </tr>
                    <tr>
                      <td>BRUNEI</td>
                      <td>KYRGYZSTAN</td>
                      <td>SINGAPORE</td>
                    </tr>
                    <tr>
                      <td>BHUTAN</td>
                      <td>LAOS</td>
                      <td>SRI LANKA</td>
                    </tr>
                    <tr>
                      <td>CAMBODIA</td>
                      <td>LEBANON</td>
                      <td>SYRIA</td>
                    </tr>
                    <tr>
                      <td>CHINA</td>
                      <td>MACAU, CHINA</td>
                      <td>CHINESE TAIPEI</td>
                    </tr>
                    <tr>
                      <td>HONG KONG, CHINA</td>
                      <td>MALAYSIA</td>
                      <td>TAJIKISTAN</td>
                    </tr>
                    <tr>
                      <td style={{ color: "#410713", fontWeight: "bold" }}>
                        INDIA*
                      </td>

                      <td>MALDIVES</td>
                      <td>THAILAND</td>
                    </tr>
                    <tr>
                      <td>INDONESIA</td>
                      <td>MONGOLIA</td>
                      <td>TURKMENISTAN</td>
                    </tr>
                    <tr>
                      <td>IRAN</td>
                      <td>MYANMAR</td>
                      <td>UAE</td>
                    </tr>
                    <tr>
                      <td>IRAQ</td>
                      <td>NEPAL</td>
                      <td>UZBEKISTAN</td>
                    </tr>
                    <tr>
                      <td>JAPAN </td>
                      <td>VIETNAM</td>
                      <td>PAKISTAN</td>
                    </tr>
                    <tr>
                      <td>JORDAN</td>
                      <td>PALESTINE</td>
                      <td></td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <p>
            * - Players from this countries can’t participate in PUBG Mobile
            competition due to official restrictions
          </p>

          <p>
            {" "}
            Players must comply with all age regulations, including national
            rules and any extra limitations set by the publishers and other
            stakeholders. Any Player considered to be a minor by laws of the
            respective country will be required to be able to confirm that they
            have parental or guardian consent to participate in the
            Championship. Additional requirements and (or) restrictions might be
            implemented by Organizers to conform to the laws and regulations of
            the respective country or game publisher. Players who compete in the
            tournament may be required to provide proof of identity. Failure to
            provide proof of identity may result in ineligibility for
            participation in the Tournament or disqualification of a Player (and
            their Team, if applicable) from the Tournament. The Championship
            officials reserves the right to verify an eligible Player’s
            information at any given time if deemed necessary. During the
            verification process, players must be able to submit an acceptable
            photo ID to Championship officials to verify their identity.
            Employees of Organizers and partner companies, as well as family
            members of such employees, are not eligible to register or compete
            in the Championship. For tournaments of Clash of Clans and Dota 2
            teams are required to register a roster of 5 players. For
            tournaments of PUBG Mobile teams are required to register a roster
            of 4 players. Substitutions and team roster changes are not allowed
            during the Championship except under force majeure circumstances.
            All such requests should be submitted to Championship officials to
            be evaluated on a case-by-case basis. Each player can only
            participate in one Tournament during the Championship. To
            participate in the Tournament, a Player must meet the following
            conditions: Be registered on the Tournament Hub; Accepted the Rules,
            Tournament Hub's terms of use, privacy policy and cookie policy; The
            age of the Player shall correspond to the minimum allowed age for
            the corresponding Tournament. Have a device and official game
            account for the respective game that conform to these Rules. Player
            is fully responsible for compliance with national laws regulating
            minimum age requirements. Playershall not register and/or
            participate in the Tournament, if Player’s national law or
            applicable regulations prohibit such participation. In order to
            maintain the integrity of the Championship and enforce fair play for
            all participants Organizers may, at their sole discretion, at any
            stage of the Championship require any of the Participants to provide
            a video recording and (or) a video broadcast verifying the identity
            of Participants and programs and devices used by Participants; in
            this case, Championship officials provide Participants specific
            instructions on the setup and output of such video recording or
            broadcast no later than 24 hours before respective match starts.
          </p>
        </div>
        <div id="3">
          <h2>3. Championship structure</h2>
          <p>
            Detailed information and rules are unique for each game. Please
            visit the Tournament page to see most recent and accurate
            regulations.
          </p>
        </div>
        <div id="4">
          <h2>4. Code of Conduct</h2>
          <p>
            The following behaviors are considered unfair play and will be
            subject to penalties including disqualifications. Collusion,
            match-fixing - agreement with a participant of a match to alter the
            result of a match. Cheating - using software or hardware to alter
            the game mechanics or behavior for any player in order to gain
            benefits otherwise unavailable in-game. Bug exploitation -
            intentional usage of any in-game bug to gain in-game advantage.
            Multi-accounting - using multiple accounts or accounts of other
            players for the matches of the Championship. Bribery - offering any
            gifts or rewards to members and workers of Organizers for services
            promised or rendered in relation to the Championship. Abuse,
            threats, discrimination, vulgar or hateful speech - using offensive
            language during communication with other Championship players or
            Championship officials. Gambling - participation in betting or
            gambling on the outcome of the Championship or any part of the
            Championship.
          </p>
        </div>
        <div id="5">
          <h2>Penalties</h2>
          <p>
            Any person that had engaged or attempted to engage in an act that
            violates or potentially violates Code of Conduct might be subject to
            a penalty. Decision on whether a specific act or attempt violates or
            potentially violates Code of Conduct is made by Championship
            officials at their sole and absolute discretion. Penalties include,
            but not limited to: warning, game loss, match loss, point
            subtraction, disqualification. Repeated infractions might lead to
            escalating penalties. Penalties are not always imposed in a
            successive manner. Teams receive penalties for infractions committed
            by their players.
          </p>
        </div>
        <div id="6">
          <h2>6. Legal aspects</h2>
          <p>
            When registering for and (or) participating in the Tournaments, each
            Player acknowledges and agrees that: The Organizer has the right to
            use Player’s name, nickname, biography, voice, photos and videos
            with Player’s participation in the Tournament on the websites, as
            well as in any public and promotional events or other promotions
            without reference to the source, additional notifications or
            compensation; The Organizer has the right to process, store and use
            personal and contact information of the Player for the purposes of
            organizing, conducting and promotion of the Tournament, including
            for communication with the Player; The Organizer is not responsible
            for any damage caused to Player, except for liability that cannot be
            excluded in accordance with applicable law; The Tournament may be
            broadcasted; Ensuring public order and public safety during the
            competition is carried out in accordance with the requirements of
            regulatory legal acts in the field of security and public order; To
            ensure public order and public safety, the competition organizers
            and/or the owners of the facility may involve private security
            companies, distribution controllers, etc. The Player has the right
            to receive information about the Tournament via the Tournament Hub.
            The participant undertakes to independently track information about
            the Tournament in any way provided for in paragraph 6.2 of these
            Rules.
          </p>
        </div>
        <div id="7">
          <h2>7. Personal data</h2>
          <p>
            Player (hereinafter referred to as "personal data subject") shall
            provide their personal data. Personal data is collected for the
            purpose of registration for and organization of the Tournament,
            issuing prizes to Player (if applicable), informing Participants of
            their winnings, advertising offers, as well as for the purpose of
            conducting marketing analysis and preparing statistical information.
            Personal data may be processed by the Organizer and/or partners and
            / or their affiliates, as well as their authorized persons, using
            automated data processing tools. The Participant has the right to
            obtain other information about the person who processes the Player's
            personal data by contacting the Organizer. The personal data subject
            has the right to receive information about the Organizer, its
            location, whether the Organizer has personal data related to the
            relevant personal data subject, as well as to get acquainted with
            such personal data, as in writing (upon a corresponding request
            containing the number of the main identity document of the personal
            data subject or its legal representative, information about the date
            of issue of the specified document and the issuing authority, as
            well as the handwritten signature of the subject of personal data or
            its legal representative). The Organizer, who has access to personal
            data, ensures the confidentiality of personal data in accordance
            with the procedure established by law. The data subject may revoke
            his consent by sending an email to a person designated by the
            Organizer with the indication in the notification of Participant’s
            name, surname, patronymic, email, age and city. The Organizer has
            the right to request data that allows identification of the personal
            data subject. In that case, Player's participation in the Tournament
            may be canceled. The fact that Player registers for the Tournament,
            confirms the Player's consent to these Rules, and is also the
            Player's consent to provide the Organizer with Player's personal
            data, their processing (including collection, systematization,
            accumulation, storage (including in case of claims), clarification
            (updating, modification), use (including, but not limited to, for
            the purpose of awarding prize (if applicable), individual
            communication with Players and providing advertising information
            about products, services and/or the Organizer and/or its partners),
            distribution (including transfer to third parties), transfer,
            including cross-border, depersonalization, blocking, and
            destruction). Personal data may be transferred from the Organizer to
            third parties engaged by the Organizer on the basis of relevant
            agreements. By participating in the Tournament, the participant
            agrees to the use by the Organizer and/or its partners and/or their
            authorized representative personal information of the Player
            (material about him) and authorizes the Organizer to take
            advertising interviews about participation in Tournament by the
            Player, including radio and television, as well as for other media,
            and distribute them or to make film, video, audio recording party
            for making any advertising materials, disclosure and further use of
            the names, surnames, biography, image, voice of the Player without
            paying any remuneration for this. This consent is given by the
            participant for the duration of the Tournament and for 5 years after
            its end, and may be withdrawn by the Player by written notification
            sent to the Organizer by registered mail. The withdrawal of consent
            to the processing of personal data by the Participant and/or other
            personal data subject whose personal data was provided by the Player
            to the Organizer/ its partners (or its affiliates) automatically
            leads to the withdrawal of the relevant Player from participation in
            the Tournament and makes it impossible to receive the prize (if
            applicable).
          </p>
        </div>
        <div id="8">
          <h2>8. Media</h2>
          <p>
            Accreditation of journalists is carried out by the Organizer.
            Participants are required to take part in interviews before and
            after matches at the request of the Organizer. Communication with
            media representatives is organized by the Organizer in special zones
            equipped with banners with Tournament sponsors. Media
            representatives should respect the Organizer and participants of the
            Tournament. Interviews can only be arranged in strictly defined and
            designated locations. Journalists should not approach Participants
            for interviews or comments during the match.
          </p>
        </div>
        <div id="9">
          <h2>9. Disputes</h2>
          <p>
            All issues that are not regulated by these Rules are resolved by the
            Organizer in accordance with applicable law. Complaints related to
            the refereeing and organization of matches are submitted in any
            written form to the Championship officials of the Tournament no
            later than 10 minutes after the end of the match. In this case, the
            Player shall declare intention to file a complaint. The complaint is
            considered by the Championship officials and/or the Organizer in the
            shortest possible time, which shall not exceed ten (10) business
            days, unless additional investigation is required by the
            Championship officials, referee or the Organizer. The final decision
            of the Championship officials and/or the Organizer on disputed
            situations is not subject to appeal. The Organizer shall have the
            ultimate decision role in all disputes.
          </p>
        </div>
      </div>
    </>
  );
};
export default RulesAsia;
