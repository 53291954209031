import React, { Component } from "react";
import styled from "styled-components";

import Logo from "../../components/logo/Logo";
import ButtonLogin from "../../components/UI/buttons/button";
import { API, API_ROUTER } from "../../api";
import { getUrlParams } from "../../helpers";

import { Styled } from "./style";

class EmailConfirm extends Component {
  state = {
    confirmed: false,
    error: false,
    emailSent: false,
  };

  componentDidMount() {
    const { history } = this.props;
    const { secret } = getUrlParams();
    !secret ? history.push("/") : this.confirmPassword(secret);
  }

  confirmPassword(secret) {
    const params = {
      ...API_ROUTER.auth.confirmEmail,
      data: {
        secret: secret,
      },
    };

    API.request(params, true)
      .then(() => this.setState({ confirmed: true, error: false }))
      .catch(() => this.setState({ confirmed: false, error: true }));
  }

  render() {
    const { confirmed, error, emailSent } = this.state;

    return (
      <StyledAuth style={{ minHeight: "1vh" }}>
        <Logo />
        <Styled.Message>
          <div>Email confirmed!</div>
          <div></div>

          <ButtonLogin
            action={() =>
              // this.props.history.push("https://bigbang.passport.gg/login")
              (window.location.href = "https://bigbang.passport.gg/login")
            }
            label="Login"
            variant="bigBang"
          />
        </Styled.Message>
        {error && (
          <Styled.Message>
            <div>Token has expired... Please try to send new Email.</div>
            <ButtonLogin
              action={() => this.setState({ emailSent: true })}
              disabled={emailSent}
              label={emailSent ? "Email sent" : "Send New Email"}
              variant="bigBang"
            />
          </Styled.Message>
        )}
      </StyledAuth>
    );
  }
}

export default EmailConfirm;
const StyledAuth = styled.div`
  background-size: cover;
  min-height: 100vh;
  padding: 85px 28px;

  & > .auth__text {
    text-align: center;
    margin: 42px 0;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
  }

  & > .back__login {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 42px;
  }

  & > .auth__form {
    border: 1px solid #999999;
    padding: 48px 12px;
    border-radius: 16px;

    & > .input__group {
      border-radius: 16px;
      overflow: hidden;

      & > .input__group__border {
        border-top: 1px solid #999999;
      }
    }

    & > .btn__group {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (max-width: 400px) {
    padding: 50px 1rem;
  }
`;
