import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";

import {
  hideAuthModal,
  showAuthModal,
  showRegisterModal,
  userOnline,
} from "../../redux/actions/auth";
import store from "../../redux/store";
import { getUserData } from "../../redux/actions/user";

//color tchema
import { mainBGColor } from "../../total/const";

import s from "./schedule.module.scss";
import SmollHeader from "../smoll-header";
import countryFlag from "../../img/triple01.jpg";

const Schedule = (props) => {
  return (
    <>
      <SmollHeader />
      <div className={s.blockContainer}>
        <div className={`${s.contentContainer} ${s.textBalck}`}>
          <FormattedMessage id="pages.schedule.mainTitle" tagName="h1" />
          <div className={s.text}>
            <FormattedMessage id="pages.schedule.p1" tagName="p" />
          </div>

          <div className={s.linkContainer}>
            <FormattedMessage id="pages.schedule.p2.first" tagName="p" />
            <a href="/rulebook-asia">
              <FormattedMessage id="pages.schedule.p2.link" tagName="p" />
            </a>
            <FormattedMessage id="pages.schedule.p2.last" tagName="p" />
          </div>
          <div className={s.text}>
            <FormattedMessage id="pages.schedule.p3" tagName="p" />
          </div>

          {/* <div className={s.tableContainer}>
            <div className={s.tBody}>
              <div className={s.tRow} style={{ background: mainBGColor }}>
                <div className={s.item} style={{ color: "#fff" }}>
                  <FormattedMessage
                    id="pages.schedule.table.header1"
                    tagName="p"
                  />
                </div>
                <div className={s.item} style={{ color: "#fff" }}>
                  <FormattedMessage
                    id="pages.schedule.table.header2"
                    tagName="p"
                  />
                </div>
                <div className={s.item} style={{ color: "#fff" }}>
                  <FormattedMessage
                    id="pages.schedule.table.header3"
                    tagName="p"
                  />
                </div>
                <div className={s.item} style={{ color: "#fff" }}>
                  <FormattedMessage
                    id="pages.schedule.table.header4"
                    tagName="p"
                  />
                </div>
                <div className={s.item} style={{ color: "#fff" }}>
                  <FormattedMessage
                    id="pages.schedule.table.header5"
                    tagName="p"
                  />
                </div>
                <div className={s.item} style={{ color: "#fff" }}>
                  <FormattedMessage
                    id="pages.schedule.table.header6"
                    tagName="p"
                  />
                </div>
              </div>
              <div className={s.tRow}>
                <div className={s.item}>
                  <FormattedMessage
                    id="pages.schedule.table.row1.item1"
                    tagName="p"
                  />
                </div>
                <div className={s.item}>
                  <p>16/12/2022 - 25/01/2023</p>
                </div>
                <div className={s.item}>
                  <p>16/12/2022 - 25/01/2023</p>
                </div>
                <div className={s.item}>
                  <p>16/12/2022 - 25/01/2023</p>
                </div>
                <div className={s.item}>
                  <p>16/12/2022 - 25/01/2023</p>
                </div>
                <div className={s.item}>
                  <p>16/12/2022 - 25/01/2023</p>
                </div>
              </div>
              <div className={s.tRow} style={{ background: mainBGColor }}>
                <div className={s.item} style={{ color: "#fff" }}>
                  <FormattedMessage
                    id="pages.schedule.table.row2.item1"
                    tagName="p"
                  />
                </div>
                <div className={s.item} style={{ color: "#fff" }}>
                  <p>30/01/2023 - 12/02/2023</p>
                </div>
                <div className={s.item} style={{ color: "#fff" }}>
                  <p>06/02/2023 - 19/02/2023</p>
                </div>
                <div className={s.item} style={{ color: "#fff" }}>
                  <p>13/02/2023 - 27/02/2023</p>
                </div>
                <div className={s.item} style={{ color: "#fff" }}>
                  <p>13/02/2023 - 05/03/2023</p>
                </div>
                <div className={s.item} style={{ color: "#fff" }}>
                  <p>13/02/2023 - 12/03/2023</p>
                </div>
              </div>
              <div className={s.tRow}>
                <div className={s.item}>
                  <FormattedMessage
                    id="pages.schedule.table.row3.item1"
                    tagName="p"
                  />
                </div>
                <div className={s.item}>
                  <p>16/01/2023 - 19/02/2023</p>
                </div>
                <div className={s.item}>
                  <p>23/02/2023 - 26/03/2023</p>
                </div>
                <div className={s.item}>
                  <p>02/03/2023 - 05/03/2023</p>
                </div>
                <div className={s.item}>
                  <p>07/03/2023 - 12/03/2023</p>
                </div>
                <div className={s.item}>
                  <p>14/03/2023 - 19/03/2023</p>
                </div>
              </div>
            </div>
          </div> */}
          <h1>The event schedule will be update soon</h1>
        </div>
        <div className={s.buttonContainerCenter}>
          <button
            type="submit"
            className={s.gradientButton}
            onClick={() => {
              store.dispatch(showRegisterModal());
              store.dispatch(showAuthModal());
            }}
          >
            <FormattedMessage id="global.buttons.signUp_now" />
          </button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userOnline: state.userOnline,
    countriesList: state.countriesList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    runGetUserData: () => dispatch(getUserData()),
    runUserOnline: () => dispatch(userOnline()),
    runDispatchHideAuthModal: () => dispatch(hideAuthModal()),
  };
};

export default injectIntl(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Schedule))
);
